@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
prp
**********************/
.prp {
    &__mv {
        background: url(./../img/treatment_prp/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
}
/**********************
p-desc
**********************/
.p-desc {
    padding: 47px 0 120px;
    @include sp() {
        padding: 55px 0;
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        margin-bottom: 66px;
        @include sp() {
            letter-spacing: normal;
            margin-bottom: 30px;
            @media screen and (max-width: 320px) {
                font-size: 1.9rem;
            }
        }
    }
    &__sec-wrap_l {
        margin-bottom: 77px;
        @include sp() {
            margin-bottom: 55px;
        }
    }
    &__sec-wrap {
        @include sp() {

        }
        &:not(:last-of-type) {
            margin-bottom: 60px;
        }
    }
}
/**********************
p-about
**********************/
.p-about {
    &__sec-wrap {
        margin-bottom: 58px;
        @include sp() {
            margin-bottom: 25px;
        }
    }
    &__cont-wrap {
        @include sp() {

        }
    }
    &__wrap_intro {
        margin-bottom: 34px;
        @include sp() {
            margin-bottom: 20px;
        }
    }
    &__img-wrap {
        text-align: center;
        margin: 0 auto 30px;
        @include sp() {
            margin: 0 auto 15px;
        }
    }
    &__img {
        @include sp() {

        }
    }
    &__txt-wrap {
        @include sp() {

        }
    }
    &__txt {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
            font-size: 1.3rem;
        }
    }
    &__desc-ttl {
        margin-bottom: 9px;
        @include sp() {
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
    }
    &__desc {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
        }
        &:not(:last-of-type) {
            margin-bottom: 25px;
            @include sp() {
                margin-bottom: 15px;
            }
        }
    }
}
/**********************
p-process
**********************/
.p-process {
    &__sec-wrap {
        margin-bottom: 62px;
        @include sp() {
            margin-bottom: 25px;
        }
    }
    &__sec-ttl {
        margin-bottom: 29px;
        @include sp() {
            margin-bottom: 15px;
        }
    }
    &__cont-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }
    }
    &__wrap {
        width: calc(50% - 40px / 2);
        @include sp() {
            width: 100%;
        }
        &.img-wrap {
            text-align: center;
            margin-right: 40px;
            @include sp() {
                margin: 0 0 15px 0;
            }
        }
    }
    &__img {
        border-radius: 16px;
        @include sp() {
            
        }
    }
    &__sec {
        @include sp() {
            
        }
        &:not(:last-of-type) {
            margin-bottom: 30px;
            @include sp() {
                margin-bottom: 10px;
            }
        }
    }
    &__ttl {
        margin-bottom: 10px;
        @include sp() {
            font-size: 1.6rem;
            margin-bottom: 0;
        }
    }
    &__sub-ttl {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        line-height: 1.3;
        margin-bottom: 10px;
        @include sp() {
            font-size: 1.4rem;
            font-weight: bold;
            letter-spacing: normal;
            line-height: 1.3;
            margin-bottom: 5px;
        }
    }
    &__note_yellow {
        color: $color_accent;
        @include sp() {

        }
    }
    &__list {
        @include sp() {
            
        }
    }
    &__item {
        display: flex;
        letter-spacing: 0.08em;
        margin-bottom: 10px;
        // line-height: 2;
        @include sp() {
            font-size: 1.3rem;
        }
        &:nth-of-type(1)::before {
            content:'①' ;
            display: inline-block;
            @include sp() {
            }
        }
        &:nth-of-type(2)::before {
            content:'②' ;
            display: inline-block;
            @include sp() {
            }
        }
        &:nth-of-type(3)::before {
            content:'③' ;
            display: inline-block;
            @include sp() {
            }
        }
        &:nth-of-type(4)::before {
            content:'④' ;
            display: inline-block;
            @include sp() {
            }
        }
    }
}
/**********************
p-therapy
**********************/
.p-therapy {
    &__sec-wrap {
        margin-bottom: 57px;
        @include sp() {
            margin-bottom: 25px;
        }
    }
    &__sec-ttl {
        @include sp() {
        }
        @media screen and (max-width: 567px) {
            min-height: 65.84px;
        }
        .sp {
            @include sp() {
                display: none;
            }
            @media screen and (max-width: 567px) {
                display: block;
            }
        }
    }
    &__cont-wrap {
        @include sp() {
            
        }
    }
    &__table {
        border: 1px solid #ddd;
        margin-bottom: 38px;
        min-height: 200px;
        /* firefox に適用 */
        height: 200px;
        @media (-ms-high-contrast: none) {
            height: 200px;
        }
        width: 100%;
        @include sp() {
            margin-bottom: 15px;
        }
    }
    &__tr {
        @include sp() {
            
        }
        &:first-of-type {
            text-align: left;
            background: #f5f5f5;
        }
        &:not(:last-of-type) {
            border-bottom: 1px solid #ddd;
        }
    }
    &__th {
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: 0.075em;
        line-height: 1.3;
        padding: 0 20px;
        vertical-align: middle;
        @include sp() {
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.3;
            padding: 0 10px;
            vertical-align: middle;
            
        }
        &:not(:last-of-type) {
            border-right: 1px solid #ddd;
            width: 570px;
            @include sp() {
                width: 50%;
            }
        }
    }
    &__td {
        font-size: 1.8rem;
        // letter-spacing: 0.075em;
        line-height: 1.3;
        padding: 0 20px;
        vertical-align: middle;
        @include sp() {
            font-size: 1.3rem;
            // letter-spacing: 0.075em;
            line-height: 1.3;
            padding: 0 10px;
            vertical-align: middle;
            
        }
        &:not(:last-of-type) {
            border-right: 1px solid #ddd;
            width: 570px;
            @include sp() {
                width: 50%;
            }
        }
    }
    &__wrap {
        @include sp() {
        }
        &:not(:last-of-type) {
            margin-bottom: 35px;
            @include sp() {
                margin-bottom: 20px;
            }
        }
    }
    &__ttl {
        margin-bottom: 10px;
        @include sp() {
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
    }
    &__txt-wrap {
        @include sp() {

        }
    }
    &__txt {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
        }
    }
}
/**********************
p-ours
**********************/
.p-ours {
    &__cont-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include sp() {
            flex-direction: column;
        }
    }
    &__img-wrap {
        text-align: center;
        width: 50%;
        @include sp() {
            margin-bottom: 15px;
            width: 100%;
        }
    }
    &__img {
        border-radius: 20px;
        @include sp() {

        }
    }
    &__feature {
        padding-left: 55px;
        width: 50%;
        @include sp() {
            padding-left: 0;
            margin-bottom: 20px;
            width: 100%;
        }
    }
    &__cost {
        margin-top: 40px;
        width: 100%;
        @include sp() {
            margin-top: 0;
        }
    }
    &__ttl {
        margin-bottom: 12px;
        @include sp() {
            font-size: 1.6rem;
            text-align: center;
            margin-bottom: 15px;
        }
    }
    &__txt {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
        }
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}
/**********************
other-sec
**********************/
.other-sec {
    .cmn-link-wrap-fff {
        width: 380px;
        @include sp() {
            width: 300px;
        }
        @media screen and (max-width: 320px) {
            width: 100%;
        }
    }
}
