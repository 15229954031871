@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";


/**********************
home
**********************/
.home {
    &__sec {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1320px;
        width: 100%;
        @include sp() {
            flex-direction: column-reverse;
            min-width: 100%;
        }
    }
    &__txt-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 68px;
        // height: 380px;
        width: 600px;
        @include sp() {
            // height: auto;
            padding: 0 14px;
            width: 100%;
        }
    }
    &__desc {
        font-size: 1.8rem;
        text-align: center;
        line-height: 2.0;
        margin-bottom: 40px;
        width: 100%;
        @include sp() {
            font-size: 1.4rem;
            text-align: left;
            margin-bottom: 25px;
        }
    }
    &__img-wrap {
        // height: 380px;
        width: 600px;
        @include sp() {
            // height: auto;
            width: 100%;
        }
    }
}
/**********************
mv
**********************/
.mv {
    background: url(./../img/top/img_key04.jpg) no-repeat center center  / cover;
    height: 560px;
    margin-top: 171px;
    min-width: 1320px;
    position: relative;
    width: 100%;
    z-index: 1;
    @include sp() {
        margin-top: 50px;
        min-width: 100%;
        height: 320px;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 200px;
        @include sp() {
            flex-direction: inherit;
            flex-wrap: wrap;
            align-content: center;
            padding-top:0;
            height: 100%;
            padding-top: 1em;
        }
    }
    &__catch {
        color: #fff;
        font-size: 7.0rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        margin-bottom: 86px;
        text-shadow: 0px 0px 10px #000, 0px 0px 18px #777;
        @include sp() {
            font-size: 3.2rem;
            margin-bottom: 25px;
            position: relative;
        }
        @media screen and (max-width: 320px) {
            font-size: 3.2rem;
        }
        &::before {
            background: #fff;
            box-shadow: 2px 0px 10px #000;
            content: '';
            height: 4px;
            max-width: 760px;
            position: absolute;
            top: 30%;
            left: 0;
            width: 100%;
            @include sp() {
                max-width: 260px;
                top: -.5em;
            }
        }
        &::after {
            background: #fff;
            box-shadow: 2px 0px 10px #000;
            content: '';
            height: 4px;
            max-width: 760px;
            position: absolute;
            bottom: 42%;
            right: 0;
            width: 100%;
            @include sp() {
                max-width: 260px;
                bottom: -.5em;
            }
        }
    }
    &__text {
        color: #fff;
        font-size: 3.2rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-shadow: 0px 0px 10px #000, 0px 0px 18px #777;
        @include sp() {
            font-size: 1.5rem;
        }
    }
}
/**********************
sec-wrap
**********************/
.sec-wrap {
    display: flex;
    justify-content: space-between;
    padding: 55px 60px 77px 60px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        flex-direction: column;
        padding: 55px 15px;
        min-width: 100%;
        @media screen and (max-width: 320px) {
        }
    }
}
/********** home-news **********/
.home-news {
    margin-right: 60px;
    // min-width: 570px;
    width: 100%;
    @include sp() {
        margin: 0 0 55px;
        min-width: 100%;
        width: 100%;
    }
    &__ttl {
        @include sp() {
            
        }
    }
    &__list {
        min-height: 195px;
        padding-top: 20px;
        margin-bottom: 50px;
        @include sp() {
            min-height: 100%;
            padding-top: 0;
            margin-bottom: 20px;
        }
    }
    &__item {
        @include sp() {
            
        }
    }
    &__item:not(:last-of-type) {
        @include sp() {
        }
    }
    &__item-link {
        @include sp() {
        }
    }
    &__time {
        @include sp() {
        }
    }
    &__link-wrap {
        @include sp() {
            
        }
    }
    &__link {
        @include sp() {
        }
        &::before {
            @include sp() {
                right: 20px;
            }
        }
        &:hover::before {
            @include sp() {
                right: 15px;
            }
        }
        &::after {
            @include sp() {
                right: 19px;
            }
        }
        &:hover::after {
            @include sp() {
                right: 14px;
            }
        }
    }
}
/********** biz-hours **********/
.biz-hours {
    min-width: 560px;
    width: 100%;
    @include sp() {
        min-width: 100%;
    }
    &__ttl {

        @include sp() {
            
        }
    }
    &__table {
        border-collapse: collapse;
        margin-bottom: 5px;
        @include sp() {
            margin: 0 auto 10px;
            width: 100%;
        }
    }
    &__tbody {
        width: 100%;
        @include sp() {
            
        }
        .biz-hours__tr_week {
            th:not(:last-of-type) {
                border-right: 1px solid #ddecff;
            }
        }
    }
    &__tr th, &__tr td {
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        padding: 16px 18px;
        @include sp() {
            font-size: 1.4rem;
            padding: 10px 7px;
            text-align: center;
        }
    }
    &__th:first-of-type, &__td:first-of-type {
        text-align: left;
        @include sp() {

        }
    }
    &__tr_week, &__tr_pm {
        background: $color_bg02;
        @include sp() {
            
        }
    }
    &__tr_am {
        background: $color_bg01;
        @include sp() {
            
        }
    }
    &__tr:not(:last-of-type) {
        border-bottom: 1px solid #fff;
        @include sp() {

        }
    }
    &__reception {
        font-weight: 700;
        margin: 0 auto;
        text-align: left;
        width: 100%;
        @include sp() {
            font-size: 1.4rem;
            margin: 0 auto 0 0;
        }
        @media screen and (max-width: 320px) {
            font-size: 1.2rem;
        }
    }
    .biz-hours__para {
        font-size: 1.6rem;
        margin: 0 0 10px;
        width: 100%;
        @include sp() {
            font-size: 1.4rem;
        }
    }
    // &__link-wrap {
    //     background: $color_accent;
    //     border: 2px solid $color_accent;
    //     transition: all  0.3s ease;
    //     @include sp() {
            
    //     }
    // }
    // &__link-wrap:hover {
    //     background: #fff;
    //     border: 2px solid $color_accent;
    //     @include sp() {
            
    //     }
    // }
    // &__link {
    //     position: relative;
    //     transition: all  0.3s ease;
    //     @include sp() {
            
    //     }
    //     &:hover {
    //         color: $color_accent;
    //         @include sp() {
                
    //         }
    //     }
    //     &::before {
    //         content: '';
    //         background: url(./../img/top/icon_tel02.png) no-repeat;
    //         display: inline-block;
    //         position: absolute;
    //         top: 21px;
    //         left: 75px;
    //         width: 16px;
    //         height: 20px;
    //         transition: all  0.3s ease;
    //         @include sp() {
    //             top: 16px;
    //             left: 55px;
    //         }
    //     }
    //     &:hover::before {
    //         content: '';
    //         background: url(./../img/top/icon_tel02_yellow.png) no-repeat;
    //     }
    // }
}
/**********************
about-sec
**********************/
.about-sec {
    background: $color_base;
    padding: 75px 0 90px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        @include sp() {
            
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }
    }
    &__item {
        border-bottom: 5px solid $color_main;
        border-radius: 0 0 10px 10px;
        margin-right: 60px;
        // overflow: hidden;
        // min-width: 359px;
        // width: 100%;
        width: calc(33.3333333% - 120px / 3);
        @include sp() {
            margin: 0 auto 25px;
            max-width: 370px;
            // min-width: 100%;
            min-width: auto;
            width: 100%;
        }
        &:last-of-type {
            margin-right: 0;
            @include sp() {
                margin: 0 auto;
            }
        }
    }
    &__img-wrap {
        @include sp() {
            
        }
    }
    &__img {
        border-radius: 10px 10px 0 0;
        @include sp() {
            
        }
    }
    &__txt-wrap {
        background: #fff;
        padding: 40px 28px;
        text-align: center;
        @include sp() {
            padding: 20px 14px 25px;
            
        }
    }
    &__item-ttl {
        border-bottom: 2px solid $color_main;
        color: $color_main;
        display: inline-block;
        font-size: 2.4rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        line-height: 2.6rem;
        margin-bottom: 30px;
        padding-bottom: 2px;
        @include sp() {
            font-size: 1.8rem;
            margin-bottom: 15px;
        }
    }
    &__item-desc {
        color: $color_txt02;
        font-size: 1.6rem;
        letter-spacing: 0.08em;
        text-align: center;
        margin-bottom: 30px;
        @include sp() {
            font-size: 1.4rem;
            margin-bottom: 15px;
        }
    }
    &__link-wrap {
        width: 100%;
        @include sp() {
            
        }
    }
    &__link-wrap {
        @include sp() {

        }
    }
    &__link {
        display: block;
        @include sp() {

        }
    }
    &__link .cmn-link-s {
        @include sp() {
            
        }
    }
    &__link:hover .cmn-link-wrap-s {
        background: $color_accent;
        border: 2px solid $color_accent;;
        @include sp() {
        }
    }
    &__link:hover .cmn-link-s {
        color: #fff;
        @include sp() {
        }
    }
}
/**********************
visitor-sec
**********************/
.visitor-sec {
    padding: 90px 60px;
    flex-direction: row-reverse;
    @include sp() {
        flex-direction: column-reverse;
        padding: 55px 15px;
    }
    &__txt-wrap {
        @include sp() {
        }
    }
    &__ttl {
        display: block;
        @include sp() {
            
        }
    }
    &__desc {
        @include sp() {
        }
    }
    &__link-wrap {
        @include sp() {
            
        }
    }
    &__link {
        @include sp() {
            
        }
    }
    &__img-wrap_sp {
        @include sp() {
            margin-bottom: 20px;
            width: 100%;
        }
    }
    &__img {
        border-radius: 20px 0px 0px 20px;
        @include sp() {
            border-radius: 8px 8px 0px 0px;
            width: 100%;
        }
    }
}
/**********************
treatment-sec
**********************/
.treatment-sec {
    padding: 80px 0 90px;
    position: relative;
    // min-width: 1180px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 0 0 55px;
        min-width: 100%;
    }
    &::before, &::after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        // width: 50vw;
        width: 50%;
        z-index: -1;
        @include sp() {
            position: static;
        }
    }
    &::before {
        background: $color_bg01;
        right: 50%;
        @include sp() {

        }
    }
    &::after {
        background: $color_bg02;
        left: 50%;
        @include sp() {

        }
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        @include sp() {
            
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }
    }
    &__item {
        max-width: 550px;
        width: 100%;
        @include sp() {
            background: $color_bg02;
            padding-bottom: 25px;
            max-width: inherit;
        }
    }
    &__item:first-of-type {
        @include sp() {
            background: $color_bg01;
            margin: 0 0 25px;
            border-radius: 8px;
        }
    }
    &__item:last-of-type {
        @include sp() {
            border-radius: 8px;
        }
        img {
            @include sp() {
                border: 1px solid #eee;
                border-radius: 8px 8px 0 0;
            }

        }
    }
    &__img-wrap {
        margin-bottom: 30px;
        @include sp() {
            margin-bottom: 0;
        }
    }
    &__img {
        border-radius: 8px 8px 0 0;
        @include sp() {
            width: 100%;
            height: auto;
        }
    }
    &__txt-wrap {
        min-height: 200px;
        @include sp() {
            min-height: 100%;
            padding: 20px 14px 0;
        }
    }
    &__item-ttl {
        font-size: 2.7rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        margin-bottom: 20px;
        @include sp() {
            font-size: 1.6rem;
            margin-bottom: 5px;
        }
    }
    &__item-desc {
        font-size: 1.8rem;
        letter-spacing: 0.08em;
        line-height: 2.0;
        margin-bottom: 30px;
        @include sp() {
            font-size: 1.4rem;
            margin-bottom: 25px;
        }
    }
}
/**********************
department
**********************/
.department-sec {
    padding: 90px 60px 120px 60px;
    @include sp() {
        padding: 0 15px 55px;
    }
    &__txt-wrap {
        @include sp() {
        }
    }
    &__ttl {
        @include sp() {
            
        }
    }
    &__desc {
        @include sp() {
        }
    }
    &__link-wrap {
        @include sp() {
            
        }
    }
    &__link {
        @include sp() {
            
        }
    }
    &__img-wrap_sp {
        @include sp() {
            margin-bottom: 20px;
            width: 100%;
        }
    }
    &__img {
        border-radius: 0px 20px 20px 0px;
        width: 100%;
        @include sp() {
            border-radius: 8px 8px 0px 0px;
        }
    }
}

