@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
department
**********************/
.department {
    @include sp() {

    }
    &__mv {
        background: url(./../img/department/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
    &__sec {
        min-width: 1320px;
        @include sp() {
            min-width: 100%;
        }
        &:nth-of-type(even) {
            background: $color_base;
            .department__symptom-wrap {
                order: 3;
                padding: 0 30px;
                @include sp() {
                    padding: 0;
                }
            }
            .department__img-wrap {
                order: 2;
                @include sp() {
                }
            }
            .department__table {
                background: #fff;
                @include sp() {
                }
            }
        }
    }
    &__cont-wrap {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: center;
        }
    }
    &__intro {
        letter-spacing: 0.08em;
        line-height: 1.5;
        margin-bottom: 30px;
        order: 1;
        width: 100%;
        @include sp() {
            letter-spacing: normal;
            margin-bottom: 15px;
        }
    }
    &__symptom-wrap {
        padding-right: 60px;
        order: 2;
        width: 50%;
        @include sp() {
            margin-bottom: 20px;
            order: 3;
            padding-right: 0;
            width: 100%;
        }
    }
    &__symptom-ttl-s {
        margin-bottom: 5px;
        @include sp() {
            margin-bottom: 15px;
        }
    }
    &__list {
        @include sp() {
            
        }
    }
    &__item {
        display: flex;
        align-items: baseline;
        letter-spacing: 0.08em;
        line-height: 1.5;
        @include sp() {
            letter-spacing: normal;
            
        }
        &::before {
            content: '●';
            color: $color_main;
            display: inline-block;
            font-size: 0.8rem;
            margin-right: 6px;
            transform: translateY(-2px); 
            @include sp() {

            }
        }
        &:not(:last-of-type) {
            margin-bottom: 15px;
            @include sp() {
                margin-bottom: 5px;
            }
        }
    }
    &__img-wrap {
        text-align: center;
        order: 3;
        width: 50%;
        @include sp() {
            margin-bottom: 15px;
            width: 100%;
            order: 2;
        }
        &.shadow {
            box-shadow: 5px 5px 10px #808080;
        }
    }
    &__img {
        @include sp() {
        }
    }
}

/**********************
orthopedics
**********************/
.orthopedics {
    padding: 48px 0 90px;
    @include sp() {
        padding: 55px 0;
    }
    &__ttl {
        margin-bottom: 84px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__ {
        @include sp() {

        }
    }
}



/**********************
rheumatism
**********************/
.rheumatism {
    padding: 50px 0 90px;
    @include sp() {
        padding: 55px 0;
    }
    &__ttl {
        margin-bottom: 84px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__item {
        line-height: 2;
        @include sp() {
            line-height: 1.6;
        }
    }
}


/**********************
dermatology
**********************/
.dermatology {
    padding: 50px 0 90px;
    @include sp() {
        padding: 55px 0;
    }
    &__ttl {
        margin-bottom: 84px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__ {
        @include sp() {

        }
    }
}


/**********************
rehabilitation
**********************/
.rehabilitation {
    padding: 50px 0 90px;
    @include sp() {
        padding: 55px 0;
    }
    &__ttl {
        margin-bottom: 84px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__item {
        line-height: 2;
        @include sp() {
            line-height: 1.6;
        }
    }
}
