@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";


/**********************
news-list-mv
**********************/
.news-list {
    &__mv {
        background: url(./../img/news/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
    &__sec-wrap {
        padding: 90px 0;
        @include sp() {
            padding: 55px 0;
            margin: 0;
        }
    }
    &__ttl {
        font-size: 3.3rem;
        margin-bottom: 90px;
        @include sp() {
            font-size: 2.1rem;
            margin-bottom: 45px;
        }
    }
    &__list {
        margin: 0 auto;
        @include sp() {
            margin: 0 auto;
            width: 100%;
        }
    }
    &__item {
        
        @include sp() {

        }
    }
    &__item-link {
        font-weight: 400;
        @include sp() {
            
        }
    }
    &__item-link:hover,
    &__item-link:hover &__time {
        color: $color_main;
    }
    &__time {
        letter-spacing: 0.08em;
        margin-right: 20px;
        @include sp() {

        }
    }
    &__link-wrap {
        @include sp() {

        }
    }
    &__link {
        @include sp() {

        }
    }
}

