@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
common
**********************/
* ,::after,::before{
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    &.active {
        @include sp() {
            height: 100%;
            overflow: hidden;
        }
    }
}
body {
    color: $color_txt02;
    font-family: $ffSans, “游ゴシック“, YuGothic, “Yu Gothic”, "ヒラギノ角ゴ ProN W3", “Hiragino Kaku Gothic ProN”, Arial, “メイリオ“, Meiryo, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    // padding-top: 50px;
    width: 100%;
    @include sp() {
        font-size: 1.4rem;
        min-width: 320px;
        overflow: hidden;
    }
}
img {
    vertical-align: bottom;
}
.pc {
    display: block;
    @include sp() {
        display: none;
    }
}
.sp {
    display: none;
    @include sp() {
        display: block;
    }
}
.cmn-inner {
    margin: 0 auto;
    max-width: 1320px;
    min-width: 1320px;
    padding: 0 60px;
    width: 100%;
    @include sp() {
        margin: 0 auto;
        max-width: 375px;
        min-width: 100%;
        padding: 0 15px;
    }
}

.cmn-ttl-s_home {
    color: $color_main;
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    @include sp() {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
}

.cmn-cont-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include sp() {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
    }
}
.cmn-width50 {
    width: calc(50% - 60px / 2);
    @include sp() {
        width: 100%;
    }
}

.cmn-link-wrap {
    background: $color_main;
    border: 1px solid $color_main;
    margin: 0 auto;
    width: 380px;
    border-radius: 30px;
    transition: all  0.3s ease;
    @include sp() {
        width: 300px;
        @media screen and (max-width: 320px) {
            width: 100%;
        }
    }
    &:hover {
        background: #fff;
        border: 1px solid $color_main;
        @include sp() {
        }
    }
    &_color_orange {
        background: $color_accent;
        border: 2px solid $color_accent;
        transition: all  0.3s ease;
        @include sp() {
            
        }
        &:hover {
            background: #fff;
            border: 2px solid $color_accent;
        }
    }
}
.cmn-link {
    color: #fff;
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    padding: 16.5px 0;
    position: relative;
    text-align: center;
    transition: all  0.3s ease;
    @include sp() {
        font-size: 1.4rem;
        padding: 15px 0;
    }
    &:hover {
        color: $color_main;
        @include sp() {
        }
    }
    &::before {
        background: #fff;
        content: '';
        position: absolute;
        top: 30px;
        right: 50px;
        width: 25px;
        height: 1px;
        transition: all  0.3s ease;
        @include sp() {
            top: 24px;
            right: 30px;
            width: 18px;
        }
    }
    &:hover::before {
        background: $color_main;
        right: 45px;
        @include sp() {
            right: 25px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        right: 49px;
        width: 10px;
        height: 0px;
        border: 1px solid;
        border-color: #fff transparent transparent transparent;
        transform: rotate(45deg);
        transition: all  0.3s ease;
        @include sp() {
            right: 29px;
            width: 8px;
        }
    }
    &:hover::after {
        border-color: $color_main transparent transparent transparent;
        right: 44px;
        @include sp() {
            right: 24px;
        }
    }
    &_color_orange {
        position: relative;
        transition: all  0.3s ease;
        @include sp() {
            
        }
        &:hover {
            color: $color_accent;
            @include sp() {
                
            }
        }
        &::before {
            content: '';
            background: url(./../img/top/icon_tel02.png) no-repeat;
            display: inline-block;
            position: absolute;
            top: 21px;
            left: 75px;
            width: 16px;
            height: 20px;
            transition: all  0.3s ease;
            @include sp() {
                top: 16px;
                left: 55px;
            }
        }
        &:hover::before {
            content: '';
            background: url(./../img/top/icon_tel02_yellow.png) no-repeat;
        }
        &::after {
            content: none;
        }
    }
}
.cmn-link-wrap-fff {
    background: #fff;
    border: 2px solid $color_main;
    margin: 0 auto;
    width: 100%;
    border-radius: 30px;
    transition: all  0.3s ease;
    @include sp() {
        width: calc(100% - 30px);
        @media screen and (max-width: 320px) {
            width: calc(100% - 30px);
        }
    }
    &:hover {
        background: $color_main;
        border: 2px solid $color_main;
        @include sp() {
            
        }
    }
}
.cmn-link-fff {
    color: $color_main;
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    padding: 16.5px 0;
    position: relative;
    text-align: center;
    transition: all  0.3s ease;
    @include sp() {
        font-size: 1.4rem;
        padding: 15px 0;
    }
    &:hover {
        color: #fff;
        @include sp() {
        }
    }
    &::before {
        background: $color_main;
        content: '';
        position: absolute;
        top: 30px;
        right: 50px;
        width: 25px;
        height: 1px;
        transition: all  0.3s ease;
        @include sp() {
            top: 24px;
            right: 30px;
            width: 18px;
        }
    }
    &:hover::before {
        background: #fff;
        right: 45px;
        @include sp() {
            right: 25px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 3px);
        right: 49px;
        width: 10px;
        height: 0px;
        border: 1px solid;
        border-color: $color_main transparent transparent transparent;
        transform: rotate(45deg);
        transition: all  0.3s ease;
        @include sp() {
            right: 29px;
            width: 8px;
        }
    }
    &:hover::after {
        border-color: #fff transparent transparent transparent;
        right: 44px;
        @include sp() {
            right: 24px;
        }
    }
}
.cmn-link-wrap-s {
    border-radius: 32.5px;
    border: 2px solid $color_accent;
    margin: 0 auto;
    width: 300px;
    transition: all  0.3s ease;
    @include sp() {
        width: 280px;
    }
    &:hover {
        background: $color_accent;
        border: 2px solid $color_accent;
        @include sp() {
        }
    }
}
.cmn-link-s {
    color: $color_accent;
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    padding: 7px 0;
    text-align: center;
    transition: all  0.3s ease;
    @include sp() {
    }
    &:hover {
        color: #fff;
        @include sp() {
        }
    }
}

.cmn-mv-under {
    min-width: 1320px;
    position: relative;
    width: 100%;
    z-index: 1;
    @include sp() {
        min-width: 100%;
    }
    &::after {
        content: '';
        background: rgba(0,0,0,0.6);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }
}
.cmn-inner-mv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    @include sp() {
        height: 320px;
        }
}
.cmn-catch-under {
    color: #fff;
    font-size: 5.4rem;
    font-weight: 900;
    letter-spacing: 0.4em;
    position: relative;
    z-index: 3;
    @include sp() {
        font-weight: 600;
        letter-spacing: 0.2em;
        font-size: 3.6rem;
        @media screen and (max-width: 320px) {
            font-size: 3.2rem;
        }
    }
    &::before {
        background: #fff;
        box-shadow: 2px 0px 10px #000;
        content: '';
        height: 4px;
        max-width: 120px;
        position: absolute;
        top: -60px;
        left: 0;
        width: 100%;
        z-index: 3;
        @include sp() {
            // max-width: 260px;
            // top: 20%;
        }
    }
    &::after {
        background: #fff;
        box-shadow: 2px 0px 10px #000;
        content: '';
        height: 4px;
        max-width: 120px;
        position: absolute;
        bottom: -60px;
        right: 24px;
        width: 100%;
        z-index: 3;
        @include sp() {
            // max-width: 260px;
            // bottom: 40%;
        }
    }
}
.cmn-catch-s {
    display: block;
    font-size: 3.2rem;
    font-weight: 900;
    letter-spacing: 0.4em;
    text-align: center;
    @include sp() {
        font-size: 2.2rem;
    }
}
.cmn-msg {
    min-height: 380px;
    @include sp() {
        min-height: 280px;
    }
    &_second {
        border-radius: 10px 0px 0px 10px;
        margin-bottom: 30px;
        @include sp() {
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 20px;
        }
    }
    &_first {
        border-radius: 0px 10px 10px 0px;
        margin-bottom: 30px;
        @include sp() {
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 5px;
        }
    }
}

.examination__msg{
    &-txt{
        margin-bottom: 15px;
        @include sp() {
            margin-bottom: 10px;
        }
    }
    &-list{
        width: 100%;
    }
    &-item{
        font-weight: 600;
        display: flex;
        font-size: 1.6rem;
        &:before{
            content: "＊";
            color: #00b5c5;
            margin-right: .25em;
            display: block;
        }
        @include sp() {
            font-size: 1.4rem;
        }
    }
}

.cmn-flex-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    font-size: 1.4rem;
    // letter-spacing: 0.08em;
    line-height: 1.6;
    padding: 30px 50px;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
    @include sp() {
        padding: 15px;
        height: auto;
    }
}

.flex-inner{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.cmn-msg {
    display: flex;
    min-height: 285px;
    flex-direction: column;
    
    // padding: 44px 40px 60px;
    padding: 40px;
    width: 50%;
    @include sp() {
        padding: 10px;
        min-height: auto;
        width: 100%;
    }
    &_first {
        background: $color_bg01;
        border: 1px solid $color_bg01;
        border-radius: 10px 0px 0px 10px;
        @include sp() {
            border-radius: 8px;
            margin-bottom: 20px;
        }
    }
    &_second {
        background: $color_bg02;
        border: 1px solid $color_bg02;
        border-radius: 0px 10px 10px 0px;
        @include sp() {
            border-radius: 8px;
        }
    }
}
.cmn-msg-txt {
    @include sp() {
        border-radius: 8px;
        font-size: 1.2rem;
        padding: 0 5px;
        text-align: left;
    }
}
.cmn-item-under {
    display: flex;
    width: 100%;
    @include sp() {
        height: auto;
    }
    .cmn-flex-wrap{
        height: auto;
        
    }
    &:nth-of-type(odd){
        .cmn-flex-wrap{
            border-radius: 0 20px 20px 0;
            @include sp() {
                border-radius: 0 0 8px 8px;
            }
        }
    }
    &:nth-of-type(even){
        .cmn-flex-wrap{
            border-radius: 20px 0 0 20px;
            @include sp() {
                border-radius: 0 0 8px 8px;
            }
        }
    }
    &:not(:last-of-type) {
        margin-bottom: 50px;
        @include sp() {
            margin-bottom: 25px;
        }
    }
    &:nth-child(odd) {
        flex-direction: row-reverse;
        @include sp() {
            flex-direction: column-reverse;
        }
        .cmn-txt-wrap {
            border-radius: 0 20px 20px 0;
            @include sp() {
                border-radius: 0 0 8px 8px;
            }
        }
        .cmn-img {
            border-radius: 20px 0 0 20px;
            @include sp() {
                border-radius: 8px 8px 0 0;
            }
        }
    }
    &:nth-child(even) {
        @include sp() {
            flex-direction: column-reverse;
        }
        .cmn-txt-wrap {
            border-radius: 20px 0 0 20px;
            @include sp() {
                border-radius: 0 0 8px 8px;
            }
        }
        .cmn-img {
            border-radius: 0 20px 20px 0;
            @include sp() {
                border-radius: 8px 8px 0 0;
            }
        }
    }
}
.cmn-txt-wrap {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100%;
    width: 100%;

    @include sp() {
        min-height: 100%;
        min-width: 100%;
        width: 100%;
    }
}
.cmn-item-desc {
    font-size: 1.6rem;
    letter-spacing: 0.08em;
    line-height: 1.5;
    width: 100%;
    @include sp() {
        letter-spacing: 0.02em;
    }
}
.cmn-img-wrap {
    // height: 100%;
    min-height: 380px;
    min-width: 600px;
    width: 50%;
    @include sp() {
        min-height: 100%;
        min-width: 100%;
        width: 100%;
    }
}
.cmn-img {
    width: 100%;
    @include sp() {
    }
}
.cmn-sec-ttl-l {
    font-size: 3.2rem;
    font-weight: 700;
    letter-spacing: 0.2em;
    margin-bottom: 80px;
    text-align: center;
    position: relative;
    @include sp() {
        font-size: 2.1rem;
        margin-bottom: 40px;
    }
    &::before {
        background: $color_main;
        content: '';
        height: 3px;
        position: absolute;
        bottom: -30px;
        right: 50%;
        width: 20px;
        @include sp() {
            bottom: -15px;
        }
    }
    &::after {
        background: #0395a2;
        content: '';
        height: 3px;
        position: absolute;
        left: 50%;
        bottom: -30px;
        width: 20px;
        @include sp() {
            bottom: -15px;
        }
    }
}
.cmn-sec-ttl-m {
    background: $color_main;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 2.7rem;
    font-weight: 700;
    min-height: 65.84px;
    @media (-ms-high-contrast: none) {
        /* IE10/11 に適用 */
        height: 65.84px;
    }
    letter-spacing: 0.08em;
    margin-bottom: 28px;
    padding-left: 27px;
    @include sp() {
        font-size: 1.8rem;
        border-radius: 5px;
        @media (-ms-high-contrast: none) {
            /* IE10/11 に適用 */
            height: 60px;
        }
        padding: 10px 15px;
        margin-bottom: 15px;
    }
    .sp {
        @include sp() {
            display: none;
        }
        @media screen and (max-width: 567px) {
            display: block;
        }
    }
}
.cmn-sec-ttl-s {
    color: $color_main;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    line-height: 1.5;
    @include sp() {
        font-size: 1.8rem;
        letter-spacing: normal;
    }
}
.cmn-msg-ttl {
    color: $color_main;
    font-size: 2.7rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    margin-bottom: 25px;
    text-align: center;
    @include sp() {
        font-size: 1.8rem;
        margin-bottom: 8px;
    }
    .se {
        display: none;
        @media screen and (max-width: 320px) {
        display: block;
        }
    }
}
.cmn-item-ttl {
    color: $color_main;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    margin-bottom: 30px;
    text-align: center;
    @include sp() {
        font-size: 1.8rem;
        letter-spacing: 0.02em;
        margin-bottom: 15px;
    }
}

.cmn-news {
    &__list {
        @include sp() {
        }
    }
    &__item {
        border-bottom: 1px solid #dddddd;
        @include sp() {
            
        }
    }
    &__item:not(:last-of-type) {
        margin-bottom: 20px;
        @include sp() {
            margin-bottom: 10px;
        }
    }
    &__item-link {
        color: $color_txt02;
        display: flex;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        padding: 0 0 20px;
        @include sp() {
            font-size: 1.4rem;
            padding: 0 0 10px;
        }
    }
    &__time {
        color: #696969;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.08em;
        margin-right: 20px;
        @include sp() {
            font-size: 1.4rem;
        }
    }
}

/**********************
header
**********************/
.header {
    background: #fff;
    min-width: 1320px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    @include sp() {
        background-color: #fff;
        min-width: 100%;
        min-height: 51px;
        width: 100%;
        @media (-ms-high-contrast: none) {
            /* IE10/11 に適用 */
            height: 51px;
        }
    }
    &.active {
        @include sp() {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            overflow: scroll;
        }
        .header__top{
            @include sp() {
                height: auto;
            }
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-width: 1320px;
        width: 100%;
        @include sp() {
            flex-direction: column;
            min-width: 100%;
            padding: 0;
            position: relative;
            height: inherit;
        }
    }
    &__inner {
        @include sp() {
            // height: 100%;
            // position: relative;
        }
    }
    &__logo {
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        padding: 30px 0 30px 60px;
        transition: all ease .3s;
        width: 320px;
        list-style: 1;
        &-sm{
            display: block;
            font-size: 1.6rem;
            @include sp() {
                font-size: 1.2rem;
            }
        }
        @media screen and (min-width: 1320px) {
            margin-left: calc((100vw - 1320px) / 2);
        }
        @include sp() {
            font-size: 1.8rem;
            order: 0;
            padding: 9px 0 9px 20px;
            width: 140px;
        }
        &.padding-non {
            padding: 10px 0 10px 60px;
            @include sp() {
                font-size: 2.2rem;
                order: 0;
                padding: 9px 0 0 20px;
                width: 140px;
            }
        }
    }
    &__logo-link {
        color: $color_txt01;
        @include sp() {
            
        }
        &:hover {
            opacity: 0.7;
        }
    }
    &__right-con {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: 30px 60px 30px 0;
        transition: all ease .3s;
        width: 840px;
        @media screen and (min-width: 1320px) {
            margin-right: calc((100vw - 1320px) / 2);
        }
        @include sp() {
            background-color: #fff;
            display: none;
            flex-direction: column;
            // left: 0;
            opacity: 0;
            order: 3;
            padding-right:0;
            padding-left: 0;
            padding-bottom: 90px;
            // position: absolute;
            // top: 379px;
            transform: translateX(40px);
            transition: .5s;   
            -webkit-overflow-scrolling: touch; 
            visibility: hidden;
            width: 100%;
        }
        &.padding-non {
            padding: 10px 60px 10px 0px;
            @include sp() {
                padding: 20px 0 60px 0;
            }
        }
        &.is_show {
            display: flex;
        }
    }
    &.active &__right-con {
        @include sp() {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
        }
    }    
    &__f-control {
        font-size: 1.5rem;
        margin-right: 30px;
        @include sp() {
            margin: 0 0 20px;
            width: 100%;
            font-size: 1.4rem;
        }
    }
    &__dl {
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__dt {
        font-size: 1.5rem;
        letter-spacing: 0.08em;
        margin-right: 15px;
        padding: 6px 0;
        @include sp() {
            font-size: 1.4rem;
            margin-right: 0;
            text-align: center;
            width: 100%;
        }
    }
    &__dd {
        background: #ddd;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        @include sp() {
            font-size: 1.4rem;
        }
    }
    &__dd.active {
        background: $color_main;
    }
    &__middle {
        border-radius: 4px 0px 0px 4px;
        @include sp() {
            
        }
    }
    &__large {
        border-radius: 0px 4px 4px 0px;
        @include sp() {
            
        }
    }
    &__btn {
        color: #fff;
        display: inline-block;
        padding: 6px 20px;
        @include sp() {

        }
    }
    &__list {
        display: flex;
        margin-right: 30px;
        @include sp() {
            justify-content: center;
            margin: 0 0 20px;
            width: 100%;
        }
    }
    &__item {
        @include sp() {
            
        }
    }
    &__item:first-of-type {
        margin-right: 15px;
        @include sp() {

        }
    }
    &__link {
        color: $color_txt02;
        display: block;
        font-size: 1.5rem;
        letter-spacing: 0.08em;
        padding-left: 30px;
        position: relative;
        @include sp() {
            
        }
        &:hover {
            opacity: 0.7;

        }
    }
    &__link_home-access::before {
        content: '';
        background: url(./../img/common/icon_car02.png) no-repeat ;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 27px;
        height: 16px;
        @include sp() {
            
        }
    }
    &__link_reservation {
        padding-left: 25px;
        @include sp() {
            
        }
    }
    &__link_reservation::before {
        content: '';
        background: url(./../img/common/icon_book02.png) no-repeat;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 22px;
        height: 16px;
        @include sp() {
            
        }
    }
    &__tel-wrap {
        @include sp() {
            width: 100%;
        }
    }
    &__tel-link {
        color: $color_txt02;
        @include sp() {
        }
    }
    &__tel {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.14em;
        padding-left: 33px;
        position: relative;
        @include sp() {
            margin: 0 0 8px;
            padding: 0;
            text-align: center;
        }
        &::before {
            content: '';
            background: url(./../img/common/icon_tel01.png) no-repeat ;
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 26px;
            height: 22px;
            @include sp() {
                content: none;
            }
        }
    }
    &__hours {
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        line-height: 1.4;
        @include sp() {
            text-align: center;
        }
    }
    &__bdr {
        @include sp() {
            display: inline-block;
            cursor: pointer;
            height: 22px;
            position: absolute;
            top: 12px;
            right: 20px;
            transition: all ease .7s;
            vertical-align: middle;
            width: 30px;
            z-index: 5;
            &:after {
                content: 'メニュー';
                display: inline-block;
                font-size: 10px;
                position: absolute;
                letter-spacing: -0.2em;
                top: 18px;
                left: -2px;
                width: calc(100% + 3px);
            }
        }
    }
    &__bdr span,
    &__bdr span:before,
    &__bdr span:after {
        @include sp() {
            background: #555;
            border-radius: 3px;
            content: '';
            display: block;
            height: 3px;
            position: absolute;
            transition: all .5s;
            width: 30px;
        }
    }
    &__bdr span:before {
        @include sp() {
            bottom: -6px;
        }
    }
    &__bdr span:after {
        @include sp() {
            bottom: -12px;
        }
    }
    &.active &__bdr span {
        @include sp() {
            position: absolute;
            top: 0;
            transform:translateY(8px) rotate(-45deg);
        }
    }
    &.active &__bdr span:before {
        @include sp() {
            opacity: 0;
        }
    }
    &.active &__bdr span:after {
        @include sp() {
            bottom: -16px;
            transform:translateY(-15px) rotate(-90deg);
        }
    }

    &.active &__bdr:after{
        top: 22px;
        content: "閉じる";
        left: 3px;
    }
}

/********** nav **********/
.header.active .nav {
    @include sp() {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
        z-index: 5;
    }
}
.nav {
    background-color: $color_main;
    font-size: 1.8rem;
    width: 100%;
    @include sp() {
        display: none;
        // left: 0;
        margin-top: 10px;
        opacity: 0;
        order: 2;
        // position: absolute;
        // top: 50px;
        transform: translateX(40px);
        transition: .5s;
        visibility: hidden;
        // width: 100%;
    }
    &.is_show {
        display: block;
    }

    &__inner {
        margin: 0 auto;
        max-width: 1320px;
        padding: 0 60px;
        width: 100%;
        @include sp() {
            max-width: 100%;
            padding: 0;
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }
    }
    &__item {
        border-right: 1px solid #0395a2;
        position: relative;
        width: 16.666%;
        @include sp() {
            border-bottom: 1px dotted #0395a2;
            border-right: none;
            width: 100%;
        }
        &:first-of-type {
            border-left: 1px solid #0395a2;
            @include sp() {
                border-left: none;
            }
        }
    }
    &__link {
        color: #fff;
        display: block;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        padding:16.5px 15px;
        text-align: center;
        transition: all  0.3s ease;
        @include sp() {
            display: flex;
            font-size: 1.4rem;
            text-align: left;
            padding:16.5px 20px;
            // position: relative;
            &.next {
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 23px;
                    right: 23px;
                    margin-left: auto;
                    content: "";
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    border-top: 2px solid #fff;
                    border-right: 2px solid #fff;
                    transform: rotate(45deg);
                }
            }
            &.more {
                position: relative;
                &::before, &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid #fff;
                }
                &::before {
                    top: 27px;
                    right: 15px;
                    transform: translateX(-50%);
                }
                &::after {
                    top: 23px;
                    right: 24px;
                    transform: rotate(90deg);
                }
            }
            &.active {
                &::after {
                    display: none;
                }
            }
        }
    }
    &__list_sub {
        transition: all .5s ease-out;
        position: absolute;
        width: 100%;
        z-index: 10;
        transition: .5s;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        @include pc() {
            display: block !important;
        }
        @include sp() {
            display: none;
            position: static;
            transition: .0s;
            overflow: visible;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
    &__link:hover, &__link_sub:hover {
        opacity: 0.3;
    }
    &__item:hover > &__list_sub {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        @include sp() {
            display: none;
        }
    }
    &__item_sub {
        background: $color_main;
        border-top: 1px solid #0395a2;
        @include sp() {
            border-top: 1px dotted #0395a2;
        }
    }
    &__link_sub {
        color: #fff;
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        padding: 16.5px 15px;
        text-align: center;
        transition: all 0.3s ease;
        @include sp() {
            font-size: 1.4rem;
            text-align: left;
            padding: 16.5px 20px 16.5px 40px;
            &.next {
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 23px;
                    right: 23px;
                    margin-left: auto;
                    content: "";
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    border-top: 2px solid #fff;
                    border-right: 2px solid #fff;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
/**********************
breadcrumbs
**********************/
.breadcrumbs {
    background: $color_bg01;
    margin-top: 172px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        margin-top: 50px;
        min-width: 100%;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        min-height: 44px;
        @media (-ms-high-contrast: none) {
            /* IE10/11 に適用 */
            height: 44px;
        }
        @include sp() {
            min-height: 100%;
            flex-wrap: nowrap;
            overflow-x: auto;
            white-space: nowrap;
        }
    }
    &__item {
        margin-right: 6px;
        @include sp() {
            @media screen and (max-width: 320px) {
                margin-right: 0px;
            }
        }
        &:nth-child(n+2) {
            display: flex;
            @include sp() {
            }
        }
        &:nth-child(3) {
            @include sp() {
                margin: 0 0 0 10px;
            }
        }
        &:nth-child(n+2)::before {
            content: '';
            box-sizing: border-box;
            border: 5px solid transparent;
            border-left: 5px solid $color_txt02;
            display: inline-block;
            height: 5px;
            width: 5px;
            vertical-align: middle;
            margin: 10px 0 0;
            @include sp() {
                
            }
        }
    }
    &__link {
        color: $color_txt02;
        display: inline-block;
        font-size: 14px;
        letter-spacing: 0.1em;
        padding: 6px 10px;
        position: relative;
        @include sp() {
            font-size: 12px;
        }
        &::after {
            position: absolute;
            bottom: 3px;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: #333;
            transform: scale(0, 1);
            transform-origin: right top;
            transition: transform .3s;
        }
        &:hover::after {
            transform-origin: left top;
            transform: scale(1, 1);
            @include sp() {
            }
        }
    }
}
/**********************
main-contents
**********************/
.main-contents{
    display: block;
}

/**********************
other-sec
**********************/
.other-sec {
    text-align: center;
    @include sp() {
    }
    &__info {
        font-size: 2.7rem;
        font-weight: 700;
        letter-spacing: 0.08em;
        margin-bottom: 22px;
        @include sp() {
            font-size: 1.8rem;
            font-weight: 700;
            letter-spacing: 0.08em;
            margin-bottom: 20px;
        }
    }
    &__link-wrap {
        display: inline-block;
        @include sp() {

        }
        &:not(:last-of-type) {
            margin: 0 60px 0 0;
            @include sp () {
                margin: 0 0 10px 0;
            }
        }
    }
}
/**********************
map
**********************/
.map {
    text-align: center;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        min-width: 100%;
    }
    &__iframe {
        border: none;
        height: calc(450/1400*100vw);
        width: 100%;
        @include sp() {
            height: calc(450/375*50vw);
        }
    }
}





/**********************
footer
**********************/
.footer {
    padding: 0 60px;
    // min-width: 1180px;
    min-width: 1320px;
    width: 100%;
    overflow: hidden;
    @include sp() {
        padding: 0;
        min-width: 100%;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1000px;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        // height: 360px;
        padding: 70px 0;
        @include sp() {
            padding: 25px 15px;
            flex-direction: column-reverse;
            width: 100%;
            max-width: 100%;
        }
    }
    &__company-wrap {
        // width: 258px;
        @include sp() {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
        }
    }
    &__logo {
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        margin: 0 0 16px;
        width: 200px;
        &-sm{
            font-size: 1.6rem;
            display: block;
            @include sp() {
                font-size: 1.0rem;
                display: block;
            }
        }
        @include sp() {
            margin: 0 auto 16px;
            width: 140px;
            font-size: 2.0rem;
        }
    }
    &__logo-link {
        color: $color_txt01;
        display: inline-block;
        @include sp() {

        }
        &:hover {
            opacity: 0.7;
        }
    }
    &__add {
        font-size: 1.6rem;
        // letter-spacing: 0.075em;
        line-height: 1.5;
        @include sp() {
            font-size: 1.4rem;
        }
    }
    &__tel {
        font-size: 1.6rem;
        letter-spacing: 0.075em;
        line-height: 1.5;
        margin-bottom: 20px;
        @include sp() {
            font-size: 1.4rem;
        }
    }
    &__tel-color {
        color: $color_main;
        display: inline-block;
        font-size: 2.4rem;
        letter-spacing: 0.075em;
        @include sp() {
            display: inline-block;
            font-size: 2.2rem;
        }
    }
    &__tel-color[href*="tel:"] {
        @include sp() {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
        }
    }
    &__link-wrap {
        width: 244px;
        @include sp() {
            
        }
    }
    &__link {
        @include sp() {
            
        }
    }
    &__biz-hours {
        &.biz-hours{
            // width: 560px;
            width: inherit;
            @include sp() {
                width: 100%;
            }
        }
        
        .biz-hours__table {
            margin: 0 0 5px auto;
            @include sp() {
                margin: 0 auto 5px;
                width: 100%;
            }
        }
        .biz-hours__reception {
            margin: 0 0 0 auto;
            width: 560px;
            @include sp() {
                margin: 0 auto 40px;
                width: 100%;
            }
        }
    }
    &__cr {
        background: $color_main;
        color: #fff;
        display: block;
        font-size: 1.2rem;
        text-align: center;
        padding: 20px 0;
        margin: 0 -200px;
        @include sp() {
            font-size: 1rem;
            margin: 0;
            width: 100%;
        }
    }
}
.layer_board_bg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    display: none;
    cursor: pointer;
    background: #333;
    @include sp() {

    }
}
.layer_board {
    cursor: pointer;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 20%;
    left: 0;
    z-index: 10;
    display: none;
    @include sp() {
        top: 20%;
        // overflow: scroll;
    }
}
.modalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 260px;
    // width: 730px;
    @include sp() {

    }
}
.modalContent .modalContent__inner {
    background-color: #fff;
    max-width: 790px;
    padding: 55px 100px;
    width: 100%;
    @include sp() {
        padding: 20px;
    }
}
.modalContent__ttl {
    font-size: 2.2rem;
    margin-bottom: 25px;
    text-align: center;
    @include sp() {
        font-size: 1.8rem;
    }
}
.modalContent__note-wrap {
    margin-bottom: 25px;
    @include sp() {

    }
}
.modalContent__note {
    color: $color_accent;
    font-size: 1.4rem;
    margin-bottom: 5px;
    text-align: center;
    @include sp() {
        font-size: 1.2rem;
        // text-align: left;
    }
}
.modalContent__txt {
    font-size: 1.6rem;
    margin-bottom: 25px;
    text-align: center;
    @include sp() {
        font-size: 1.2rem;
        // text-align: left;
    }
}
.modal__tel {
    color: $color_txt02;
    font-size: 2.2rem;
    @include sp() {
        font-size: 1.8rem;

    }
}
.modal__close {
    cursor: pointer;
    height: 100px;
    margin-left: auto;
    position: relative;
    top: -500px;
    right: 0;
    width: 100px;
    z-index: 11;
    @include sp() {
        top: -400px;
        right: -10px;
    }
}
.modal__close:before {
    transform: rotate(45deg);
}
.modal__close:after {
    transform: rotate(-45deg);
}
.modal__close:before, .modal__close:after {
    border-top: 2px solid #ccc;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: 40px;
    right: 30%;
    width: 35px;
}
.link-box {
    display: flex;
    justify-content: space-between;
    @include sp() {
    }
}
.cmn-link-wrap.modalIn {
    width: 240px;
    @include sp() {
        width: 200px;
        @media screen and (max-width:568px) {
            width: 135px;
        }
    }
    .cmn-link {
        @include sp() {
            padding: 10px 0;
        }
        &::before {
            @include sp() {
                top: 20px;
                right: 18px;
            }
        }
        &:hover::before {
            @include sp() {
                right: 14px;
            }
        }
        &::after {
            @include sp() {
                top: 18px;
                right: 16px;
            }
        }
        &:hover::after {
            @include sp() {
                right: 13px;
            }
        }
    }
}
.cmn-link-wrap.btn_gray {
    background: gray;
    border: 1px solid gray;
    @include sp() {
    }
    &:hover {
        background: #fff;
        border: 1px solid gray;
        @include sp() {
        }
    }
    .cmn-link:hover {
        color: gray;
    }
    .cmn-arrow:hover::before {
        background: gray;
    }
    .cmn-arrow:hover::after {
        border-color: gray transparent transparent transparent;
    }
}




.under-subsec{
    padding-top: 47px;
    padding-bottom: 80px;
    min-width: 1320px;
    &_bgb{
        background: $color_base;
    }
    @include sp() {
        min-width: 100%;
    }
}