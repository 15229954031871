@charset "utf-8";

/* 設定ファイル読み込み */
@import "_function";

@import "~html5-reset";

@import "common";

@import "top";

@import "visitor";

@import "treatment";

@import "treatment_prp";

@import "department";

@import "news";

@import "news_mmdd";

@import "about_greeting";

@import "about_research";

@import "about_directors";



