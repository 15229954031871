@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
treatment-mv
**********************/
.treatment__mv {
    background: url(./../img/treatment/img_key01.jpg) no-repeat center / cover;
}
/**********************
examination
**********************/
.examination {
    padding: 47px 0 89px;
    @include sp() {
        padding: 55px 0;
    }
    &__ttl {
        margin-bottom: 67px;
        @include sp() {
            margin-bottom: 40px;
        }
    }
    &__inner {
        @include sp() {

        }
    }
    &__sec-wrap {
        @include sp() {
            
        }
        &:not(:last-of-type) {
            margin-bottom: 60px;
            @include sp() {
                margin-bottom: 25px;
            }
        }
        &:nth-of-type(even) {
            @include sp() {
    
            }
            .examination__about-wrap {
                flex-direction: row-reverse;

                @include sp() {
                    flex-direction: column;
                }
            }
            .examination__about {
                padding: 0 60px 0 0;
                @include sp() {
                    padding: 0;
                }
            }
            .examination__msg.cmn-msg_first {
                border-radius: 0px 10px 10px 0px;
                order: 3;
                margin-bottom: 0;
            }
            .examination__msg.cmn-msg_second {
                border-radius: 10px 0px 0px 10px;
                order: 2;
                margin-bottom: 0;
                @include sp() {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__sec-ttl {
        
        @include sp() {

        }
    }
    &__cont-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include sp() {
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__about-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 43px;
        width: 100%;
        @include sp() {
            flex-direction: column;
            margin-bottom: 25px;
        }
    }
    &__img-wrap {
        // height: 380px;
        width: 50%;
        @include sp() {
            margin-bottom: 5px;
            width: 100%;
        }
    }
    &__img {
        border-radius: 20px;
        width: 100%;
        @include sp() {
            border-radius: 8px;
        }
    }
    &__about {
        padding-left: 60px;
        width: 50%;
        @include sp() {
            padding-left: 0;
            width: 100%;
        }
    }
    &__about-ttl {
        color: $color_main;
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: 0.08em;
        margin-bottom: 20px;
        @include sp() {
            font-size: 1.8rem;
            text-align: center;
            margin-bottom: 5px;
        }
    }
    &__about-txt {
        font-size: 1.6rem;
        letter-spacing: 0.08em;
        line-height: 1.5;
        @include sp() {
            font-size: 1.4rem;
        }
        &:not(:last-of-type) {
            margin-bottom: 30px;
            @include sp() {
                margin-bottom: 15px;
            }
        }
    }
    &__msg {
        @include sp() {

        }
    }
    &__msg-ttl {
        @include sp() {

        }
    }
    &__flex-wrap {
        @include sp() {
        }
    }
    &__flex-wrap_mh_518 {
        min-height: 518px;
        @include sp() {
            min-height: 98px;
        }
    }
    &__flex-wrap_mh_671 {
        min-height: 671px;
        @include sp() {
            min-height: 98px;
        }
    }
    &__flex-wrap_mh_299 {
        min-height: 299px;
        @include sp() {
            min-height: 98px;
        }
    }
    &__flex-wrap_mh_224 {
        min-height: 224px;
        @include sp() {
            min-height: 98px;
        }
    }
    &__msg-txt {
        @include sp() {
            
        }
    }
}
/**********************
surgery
**********************/
.surgery {
    background: $color_base;
    padding: 80px 0 87px;
    min-width: 1320px;
    // width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__inner {

        @include sp() {
        }
    }
    &__ttl {
        margin-bottom: 71px;
        @include sp() {
            margin-bottom: 40px;
        }
    }
    &__list {
        @include sp() {

        }
    }
    &__item {
        @include sp() {

        }
    }
    &__flex-wrap {
        @include sp() {

        }
    }
    &__txt-wrap {
        @include sp() {

        }
    }
    &__item-ttl {
        letter-spacing: 0.06em;
        line-height: 1.5;
        margin-bottom: 25px;
        text-align: left;
        width: 100%;
        @include sp() {
            font-size: 1.8rem;
            letter-spacing: 0.02em;
            margin-bottom: 10px;
        }
    }
    &__item-desc {
        @include sp() {

        }
    }
    &__img-wrap {
        @include sp() {

        }
    }
    &__img {
        @include sp() {

        }
    }
}
/**********************
results
**********************/
.results {
    padding: 80px 0 120px;
    @include sp() {
        padding: 55px 0;
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        margin-bottom: 64px;
        @include sp() {
            margin-bottom: 40px;
        }
    }
    &__list {
        margin-bottom: 77px;
        @include sp() {
            margin-bottom: 55px;
        }
    }
    &__item {
        @include sp() {
        }
        &:not(:last-of-type) {
            margin-bottom: 39px;
            @include sp() {
                margin-bottom: 25px;
            }
        }
        &.results__item_other {
            display: inline-block;
            margin: 0 25px 0 0;
            min-width: 857px;
            @include sp() {
                display: block;
                margin: 0 0 25px 0;
                min-width: 345px;
                @media screen and (max-width: 320px) {
                    min-width: 290px;
                }
            }
            .results__table {
                @include sp() {
                    
                }
            }
            .results__th {
                width: auto;
                @include sp() {
                }
                &:first-of-type {
                    width: 200px;
                    @include sp() {
                        width: 100px;
                    }
                }
            }
            .results__td {
                width: auto;
                @include sp() {
                    
                }
                &:first-of-type {
                    width: 200px;
                    @include sp() {
                        width: 100px;
                    }
                }
            }
        }
        &.results__item_total {
            display: inline-block;
            min-width: 314px;
            @include sp() {
                display: block;
                min-width: 345px;
                @media screen and (max-width: 320px) {
                    min-width: 290px;
                }
            }
            .table-wrap {
                @include sp() {
                    // overflow: scroll;
                }
            }
            .results__table {
                @include sp() {
                    min-width: 345px;
                    @media screen and (max-width: 320px) {
                        min-width: 290px;
                    }
                }
            }
            .results__tr {
                @include sp() {

                }
                &:first-of-type {
                    background: $color_main;
                    color: #fff;
                }
            }
            .results__th {
                width: auto;
                @include sp() {
                    
                }
                &:first-of-type {
                    @include sp() {
                        @media screen and (max-width: 320px) {
                            width: 100px;
                        }
                    }
                }
            }
            .results__td {
                width: auto;
                @include sp() {
                    
                }
                &:first-of-type {
                    background: $color_main;
                    color: #fff;
                    @include sp() {
                        @media screen and (max-width: 320px) {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
    &__item-ttl {
        color: $color_main;
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: 0.08em;
        margin-bottom: 14px;
        @include sp() {
            font-size: 1.8rem;
        }
    }
    &__table-wrap {
        @include sp() {
            overflow: scroll;
        }
    }
    &__table {
        border: 1px solid #dddddd;
        min-height: 172px;
        text-align: center;
        width: 100%;
        @include sp() {
            min-width: 600px;
        }
    }
    &__table.col {
        .results__th {
            width: calc(100% / 7);
        }
        .results__td {
            width: calc(100% / 7);
        }
    }
    &__tr {
        @include sp() {
            
        }
        &:first-of-type {
            background: #f5f5f5;
        }
        &:not(:last-of-type) {
            border-bottom: 1px solid #dddddd;

        }
    }
    &__th {
        font-weight: 400;
        vertical-align: middle;
        width: calc(100% / 6);
        @media (-ms-high-contrast: none) {
            /* IE10/11 に適用 */
            width: 16.6%;
        }
        @include sp() {
        }
        &:not(:last-of-type) {
            border-right: 1px solid #dddddd;
        }
    }
    &__td {
        vertical-align: middle;
        width: calc(100% / 6);
        @media (-ms-high-contrast: none) {
            /* IE10/11 に適用 */
            width: 16.6%;
        }
        @include sp() {
            
        }
        &:first-of-type {
            background: #f5f5f5;
            border-bottom: 1px solid #dddddd;
        }
        &:not(:last-of-type) {
            border-right: 1px solid #dddddd;
        }
    }
}

