@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
directors
**********************/
.directors {
    &__mv {
        background: url(./../img/about_directors/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
    &__sec-wrap_history {
        margin: 0 0 90px;
        @include sp() {
            margin: 0 0 25px;
        }
    }
    &__sec-ttl_history {
        margin-bottom: 30px;
        @include sp() {
            margin-bottom: 15px;
        }
    }
    &__cont-wrap_history {
        @include sp() {

        }
    }
    &__dl_history {
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 20px 0;
        @include sp() {
            flex-direction: column;
            min-height: 100%;
            padding: 5px 0;
        }
    }
    &__dt_history {
        font-size: 1.8rem;
        letter-spacing: 0.075em;
        line-height: 1.3;
        margin-right: 60px;
        min-width: 90px;
        @include sp() {
            // font-size: 1.4rem;
            font-size: 1.3rem;
            // letter-spacing: 0.075em;
            line-height: normal;
            margin-right: 0;
            min-width: 100%;
        }
        &.term {
            min-width: 320px;
            @include sp() {
                min-width: 100%;
            }
        }
    }
    &__dd_history {
        font-size: 1.8rem;
        letter-spacing: 0.075em;
        line-height: 1.4;
        min-width: 945px;
        @include sp() {
            // font-size: 1.4rem;
            font-size: 1.3rem;
            letter-spacing: normal;
            line-height: normal;
            min-width: 100%;
            width: 100%;
            // padding-left: 10px;
        }
        &.term {
            min-width: 545px;
            @include sp() {
                min-width: 100%;
            }
        }
    }
    &__detail_history {
        display: block;
        font-size: 1.2rem;
        letter-spacing: 0.075em;
        line-height: 1.5;
        @include sp() {
            display: block;
            font-size: 1.1rem;
            letter-spacing: normal;
            line-height: normal;
        }
    }
    &__time_history {
        font-weight: 700;
        @include sp() {
        }
    }
    &__item{
        margin-bottom: 75px;
        @include sp() {
            margin-bottom: 45px;
        }
    }
    &__item-inner{
        display: flex;
        align-items: center;
        @include sp() {
            flex-wrap: wrap;
        }
    }
    &__thumb{
        flex-basis: 300px;
        max-width: 300px;
        width: 300px;
        display: block;
        @include sp() {
            flex-basis: 100%;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    &__desc{
        padding-left: 30px;
        width: calc(100% - 330px);
        flex-basis: calc(100% - 330px);
        max-width: calc(100% - 330px);
        @include sp() {
            padding-left: 0;
            padding-top: 20px;
            flex-basis: 100%;
            width: 100%;
            max-width: 100%;
        }
    }
    &__head{
        font-size: 2.7rem;
        margin-bottom: 15px;
        @include sp() {
            font-size: 1.8rem;
            text-align: center;
        }
    }
    &__data{
        font-weight: 700;
        display: block;
        font-size: 1.6rem;
        @include sp() {
            font-size: 1,4rem;
        }
    }
    &__name{
        font-weight: 700;
        display: block;
    }
    &__details{
        line-height: 2;
        @include sp() {
            line-height: 1.5;
        }
    }
    &__histories{
        margin-top: 30px;
        margin-bottom: 30px;
        @include sp() {
            margin:20px 0;
        }
    }
    &__detail-list{
        background-color: #fff;
        padding:30px;
        border-radius: 10px;
        @include sp() {
            padding: 15px;
            border: 6px;
        }
    }
    &__detail-item{
        margin-bottom: .75em;
        line-height: 1.75;
        display: flex;

        &:before{
            content:"＊";
            display: block;
            margin-right: .5em;
            color: #00b5c5;
            font-weight: 700;
        }
        &:nth-of-type(even){
            &:before{
                color: #0395a2;
            }
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }



    // other
    &__other-sec {
        @include sp() {

        }
    }
    &__info_other {
        @include sp() {

        }
    }
    &__link-wrap_other {
        display: inline-block;
        @include sp() {
            display: block;
        }
        &.cmn-link-fff {
            width: 380px;
            @include sp() {
                width: 300px;
                @media screen and (max-width: 320px) {
                    width: 100%;
                }
            }
        }
        &:not(:last-of-type) {
            margin-right: 60px;
            @include sp() {
                margin: 0 auto 25px;
            }
        }
    }
    &__link_other {
        @include sp() {
        }
    }
}
/**********************
map
**********************/
.map {
    text-align: center;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        min-width: 100%;
    }
    &__iframe {
        border: none;
        height: calc(450/1400*100vw);
        width: 100%;
        @include sp() {
            height: calc(450/375*50vw);
        }
    }
}
