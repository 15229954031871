@charset "utf-8";

$color_gra01: #eaf7fb;
$color_gra02: #d3ecf6;
$color_base: linear-gradient(to right, $color_gra01, $color_gra02);
$color_bg01: #eaf7fb;
$color_bg02: #d3ecf6;
$color_main: #00b5c5;
$color_accent: #ff7800;
$color_txt01: #000;
$color_txt02: #333;
$ffSans: 'Noto Sans JP';


// mixin 
@mixin sp {
	@media screen and (max-width: 768px) {
		@content;
	}
}
@mixin pc {
	@media screen and (min-width: 769px) {
		@content;
	}
}
