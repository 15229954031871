@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
news-content
**********************/
.news-content {
    &__wrap {
        padding: 90px 0;
        @include sp() {
            padding: 55px 0;
            margin: 0;
        }
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__article {
        margin-bottom: 60px;
        // width: 50%;
        @include sp() {
            margin-bottom: 30px;
            // width: 100%;
        }
    }
    &__time {
        display: block;
        margin-bottom: 20px;
        @include sp() {
            margin-bottom: 10px;
        }
    }
    &__ttl {
        display: flex;
        font-size: 3.3rem;
        font-weight: bold;
        // letter-spacing: 0.2em;
        margin-bottom: 30px;
        @include sp() {
            font-size: 2.1rem;
            margin-bottom: 30px;
        }
        &::before {
            content: '';
            background: $color_main;
            border-radius: 33px;
            display: inline-block;
            height: auto;
            width: 5px;
            margin: 10px 20px 10px 0;
            transform: translateY(4px);
            @include sp() {
                margin: 5px 10px 5px 0;
                transform: translateY(2px);
                width: 5px;
            }
        }
    }
    &__txt {
        line-height: 2;
        @include sp() {
            line-height: 1.5;
        }
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
    &__txt-link {
        color: #00b5c5;
        font-weight: 700;
        text-decoration: underline;
    }
    &__link-wrap {
        width: 50%;
        @include sp() {
        }
        @media screen and (max-width:567px) {
            width: 100%;
        }
    }
    &__link {
        @include sp() {

        }
    }
}
