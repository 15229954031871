@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
research
**********************/
.research {
    // padding: 47px 0 130px;
    @include sp() {
        // padding: 55px 0;
    }
    &__mv {
        background: url(./../img/about_research/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
    &__sec-wrap {
        @include sp() {
        }
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__sec_l {
        padding: 77px 0;
        min-width: 1320px;
        @include sp() {
            padding: 55px 0;
            min-width: 100%;
        }
        &:nth-of-type(even) {
            background: $color_base;
            @include sp() {

            }
        }
    }
    &__ttl {
        margin-bottom: 90px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__sec-wrap_m {
        @include sp() {

        }
    }
    &__sec_m {
        @include sp() {
        }
        &:not(:last-of-type) {
            margin-bottom: 60px;
            @include sp() {
                margin-bottom: 30px;
            }
        }
    }
    &__sec-wrap_s {
        @include sp() {

        }
    }
    &__sec_s {
        padding-bottom: 30px;
        border-bottom: 1px solid #ddd;
        @include sp() {
            padding-bottom: 20px;
        }
        &:not(:last-of-type) {
            margin-bottom: 30px;
            @include sp() {
                margin-bottom: 20px;
            }
        }
    }
    &__desc-ttl {
        font-size: 1.8rem;
        @include sp() {
            font-size: 1.5rem;
            // margin-bottom: 5px;
        }
    }
    &__txt01 {
        font-size: 1.6rem;
        line-height: 2;
        @include sp() {
            font-size: 1.4rem;
            line-height: 1.5;
        }
    }
    &__txt02 {
        color: #696969;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 2;
        @include sp() {
            font-size: 1.2rem;
            line-height: 1.5;
        }
    }
    &__cont-wrap {
        // display: flex;
        @include sp() {
            
        }
    }
    &__img-wrap {
        @include sp() {

        }
    }
    &__img {
        @include sp() {

        }
    }
    &__name-wrap {
        @include sp() {

        }
    }
    &__name {
        @include sp() {

        }
    }
    &__other-sec {
        padding: 77px 0 120px;
        @include sp() {
            padding: 55px 0;
        }
    }
}
