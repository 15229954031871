@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
visitor-mv
**********************/
.visitor__mv {
    background: url(./../img/visitor/img_key01.jpg) no-repeat center / cover;
    @include sp() {
    }
}
/**********************
visitor-info
**********************/
.visitor-info {
    padding: 48px 0 90px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__ttl {
        @include sp() {
        }
    }
}
/**********************
v-about
**********************/
.v-about {
    &__cont-wrap {
        margin-bottom: 60px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__list {
        margin: 0 0 58px;
        @include sp() {
            margin: 0;
        }
    }
    &__item {
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        @include sp() {
            
        }
        &:not(:last-of-type) {
            margin-bottom: 20px;
            @include sp() {
                
            }
        }
    }
    &__item-ttl {
        text-align: left;
        margin-bottom: 5px;
        @include sp() {
            margin-bottom: 0;
        }
    }
    &__item-desc {
        letter-spacing: 0.2em;
        margin-bottom: 3px;
        @include sp() {
            letter-spacing: 0.1em;
        }
    }
    &__item-note {
        display: block;
        color: $color_accent;
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        @include sp() {
            font-size: 1.2rem;
            letter-spacing: 0.1em;
        }
        @media screen and (max-width: 320px) {
            font-size: 1.1rem;
        }
    }
    &__biz-hours {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: calc(50% - 60px / 2);
        @include sp() {
            margin-bottom: 55px;
            width: 100%;
        }
        .biz-hours__table {
            width: 100%;
            @include sp() {
            }
        }
        .biz-hours__tbody {
            width: 100%;
            @include sp() {
                
            }
            .biz-hours__tr_week {
                th:not(:last-of-type) {
                    border-right: 1px solid #d8eef7;
                }
            }
        }
        .biz-hours__tr th,
        .biz-hours__tr td {
            font-size: 1.8rem;
            font-weight: 600;
            letter-spacing: 0.08em;
            padding: 16px 18px;
            @include sp() {
                font-size: 1.4rem;
                padding: 10px 7px;
                text-align: center;
            }
        }
        .biz-hours__th:first-of-type,
        .biz-hours__td:first-of-type {
            text-align: left;
            @include sp() {
                
            }
        }
        .biz-hours__tr_week,
        .biz-hours__tr_pm {
            background: $color_bg02;
            @include sp() {
                
            }
        }
        .biz-hours__tr_am {
            background: $color_bg01;
            @include sp() {
                
            }
        }
        .biz-hours__tr:not(:last-of-type) {
            border-bottom: 1px solid #fff;
            @include sp() {
            }
        }
    }
    &__flex-wrap {
        min-height: 106px;
        align-items: center;
        @include sp() {
            min-height: 70px;
        }
    }
}
/**********************
.v-tel
**********************/
#v-tel > div > div.v-tel__note-wrap{
    @include sp() {
        order: 6;
    }
}

.v-tel {
    width: 100%;
    
    @include sp() {

    }
    &__sec-ttl {
        @include sp() {
            
        }
    }
    &__cont-wrap {
        align-items: flex-start;
        @include sp() {
        }
    }
    &__intro {
        margin-bottom: 50px;
        @include sp() {
            margin-bottom: 20px;
            order:2;
        }
    }
    &__intro-txt {
        letter-spacing: 0.08em;
        line-height: 1.4;
        @include sp() {
            letter-spacing: normal;
            line-height: normal;
        }
        &:last-of-type {
            margin-bottom: 10px;
            @include sp() {

            }
        }
    }
    &__note-wrap {
        @include sp() {

        }
    }
    &__note {
        color: #db0000;
        font-weight: 700;
        letter-spacing: 0.08em;
        line-height: 1.4;
        @include sp() {
            letter-spacing: normal;
            line-height: normal;
        }
    }
    &__hours-sec {
        margin-bottom: 50px;
        @include sp() {
            margin-bottom: 20px;
            order: 1;
        }
    }
    &__hours-ttl {
        text-align: left;
        line-height: normal;
        margin-bottom: 15px;
        @include sp() {
            text-align: center;
            letter-spacing: 0.02em;
        }
    }
    &__flex-wrap {
        padding:0 0 0 0;
        justify-content: center;
        &:nth-of-type(odd) {
            .v-tel__dl {
                background: $color_bg02;
            }
        }
        &:nth-of-type(even) {
            .v-tel__dl {
                background: $color_bg01;
            }
        }
    }
    &__dl {
        display: flex;
        align-items: center;
        min-height: 58px;
        width: 100%;
        @include sp() {
            min-height: 30px;
        }
    }
    &__dt {
        font-size: 1.8rem;
        letter-spacing: 0.08em;
        padding-left: 20px;
        width: 164px;
        @include sp() {
            font-size: 1.6rem;
            padding-left: 0px;
            text-align: center;
            width: 100%;
        }
    }
    &__dd {
        font-size: 18px;
        letter-spacing: 0.08em;
        padding-left: 20px;
        letter-spacing: 0.08em;
        width: 406px;
        @include sp() {
            font-size: 1.6rem;
            padding-left: 0px;
            // text-align: center;
            width: 100%;
        }
    }
    &__msg {
        min-height: 380px;
        @include sp() {
            min-height: 280px;
        }
        &.cmn-msg_second {
            border-radius: 10px 0px 0px 10px;
            margin-bottom: 15px;
            @include sp() {
                border-radius: 10px 10px 10px 10px;
                margin-bottom: 20px;
                order:4;
            }
        }
        &.cmn-msg_first {
            border-radius: 0px 10px 10px 0px;
            margin-bottom: 15px;
            @include sp() {
                border-radius: 10px 10px 10px 10px;
                margin-bottom: 5px;
                order: 5;
            }
        }
    }
    &__msg-ttl {
        margin-bottom: 35px;
        @include sp() {
            margin-bottom: 20px;
        }
        .se {
            display: none;
            @media screen and (max-width: 320px) {
            display: block;
            }
        }
    }
    &__msg-txt {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        text-align: center;
        min-height: 207px;
        padding: 0 15px;
        @include sp() {
        }
    }
    &__img-wrap {
        @include sp() {

        }
    }
    &__img {
        @include sp() {

        }
    }
    &__link-wrap {
        font-size: 1.6rem;
        width: 100%;
        margin-top: 5px;
        // letter-spacing: 0.08em;
        // line-height: 2;
        @include sp() {
            font-size: 1.4rem;
            // line-height: 2;
        }
    }
    &__link {
        color: $color_txt02;
        font-weight: 700;
        position: relative;
        @include sp() {
        }
        &::before {
            content: '';
            background: $color_txt02;
            height: 1px;
            position: absolute;
            bottom: -5px;
            width: 100%;
        }
    }
    &__note-wrap {
        @include sp() {
            width: 100%;
            order: 3;
        }
    }
    &__txt {
        @include sp() {
            
        }
        &_tel {
            color: $color_txt02;
            font-size: 4.2rem;
            font-weight: 700;
            @include sp() {
                font-size: 3.0rem;
            }
            @media screen and (max-width: 320px) {
                font-size: 2.6rem;
            }
            &::before {
                background: url(./../img/visitor/img_tel01.png) no-repeat;
                content: '';
                display: inline-block;
                width: 32px;
                height: 43px;
                margin-right: 5px;
                transform: translateY(5px);
                @include sp {
                }
            }
        }
        &_info {
            line-height: 1.5;
            @include sp() {
                font-size: 1.4rem;
                letter-spacing: -0.1em;
                line-height: 2;
                width: 100%;
            }
            .se {
                display: block;
                @media screen and (max-width: 320px) {
                    display: none;
                }
            }
        }
    }
}
/**********************
enter-info
**********************/
.enter-info {
    background: $color_base;
    padding: 60px 0 90px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__ttl {
        margin-bottom: 82px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__list {
        @include sp() {

        }
    }
    &__item {
        @include sp() {
            height: auto;
        }
    }
    &__item:not(:last-of-type) {
        @include sp() {

        }
    }
    &__item:nth-child(odd) {
        @include sp() {
        }
        .enter-info__txt-wrap {
            @include sp() {
            }
        }
        .enter-info__img {
            @include sp() {
            }
        }
    }
    &__item:nth-child(even) {
        @include sp() {
        }
        .enter-info__txt-wrap {
            @include sp() {
            }
        }
        .enter-info__img {
            @include sp() {
            }
        }
    }
    &__link {
        color: $color_main;
        font-weight: 500;
    }
    &__link:hover {
        opacity: 0.7;
    }
}
/**********************
portable
**********************/
.portable {
    padding: 80px 0 116px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__cont-wrap:not(:last-of-type) {
        margin-bottom: 40px;
        @include sp() {
            margin-bottom: 20px;
        }
    }
    &__span {
        display: block;
        margin-bottom: 9px;
        @include sp() {
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
    }
    &__txt {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
        }
        &:not(:last-of-type) {
            margin-bottom: 25px;
            @include sp() {
                margin-bottom: 15px;
            }
        }
    }
    &__item {
        line-height: 1.5;
        letter-spacing: 0.08em;
        @include sp() {
            letter-spacing: normal;
        }
        &:not(:last-of-type) {
            margin-bottom: 25px;
            @include sp() {
                margin-bottom: 15px;
            }
        }
    }
    &__item-note {
        color: #888;
        display: inline-block;
        font-size : 1.2rem;
        font-weight : 600;
    }
}

/**********************
access
**********************/
.access {
    background: $color_base;
    padding: 80px 0 116px;
    min-width: 1320px;
    width: 100%;
    @include sp() {
        padding: 55px 0;
        min-width: 100%;
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        margin-bottom: 65px;
        @include sp() {
            margin-bottom: 30px;
        }
    }
    &__wrap {
        display: flex;
        @include sp() {
            flex-direction: column-reverse;
        }
    }
    &__table {
        border: 1px solid #ddd;
        margin-right: 60px;
        min-height: 194px;
        /* firefox に適用 */
        height: 194px;
        @media (-ms-high-contrast: none) {
            height: 194px;
        }
        @include sp() {
            margin-right: 0;
            width: 100%;
        }
    }
    &__width_50 {
        // width: calc(50% - 60px / 2);
        @include sp() {
            // width: 100%;
        }
    }
    &__tr {
        // display: flex;
        // min-height: 58px;
        @include sp() {
        }
    }
    &__tr:first-of-type {
        // min-height: 80px;
        @include sp() {
        }
    }
    &__tr:not(:last-of-type) {
        border-bottom: 1px solid #dddddd;
        @include sp() {
            
        }
    }
    &__th {
        background: #f5f5f5;
        border-right: 1px solid #dddddd;
        padding: 0 20px;
        font-size: 1.6rem;
        letter-spacing: 0.075em;
        line-height: 1.5;
        text-align: left;
        vertical-align: middle;
        @include sp() {
            font-size: 1.3rem;
            letter-spacing: 0em;
            padding: 0 10px;
            @media screen and (max-width: 320px) {
                font-size: 1.1rem;
                padding: 0 5px;
            }
        }
    }
    &__td {
        background: #fff;
        font-size: 1.8rem;
        letter-spacing: 0.075em;
        line-height: 1.3;
        padding: 0 20px;
        vertical-align: middle;
        @include sp() {
            font-size: 1.3rem;
            letter-spacing: 0em;
            padding: 0 10px;
            @media screen and (max-width: 320px) {
                padding: 0 5px;
                font-size: 1.1rem;
            }
        }
    }
    &__img-wrap {
        @include sp() {
        }
    }
    &__img {
        // width: 100%;
        @include sp() {
            width: 100%;
        }
    }
}