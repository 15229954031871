@charset "UTF-8";
/* 設定ファイル読み込み */
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary { margin: 0; padding: 0; border: 0; font-size: 100%; font-weight: normal; vertical-align: baseline; background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary { display: block; }

/* Handle box-sizing while better addressing child elements: http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img, object, embed { max-width: 100%; }

/* Note: keeping IMG here will cause problems if you're using foreground images as sprites. In fact, it *will* cause problems with Google Maps' controls at small size. If this is the case for you, try uncommenting the following: #map img { max-width: none; } */
/* force a vertical scrollbar to prevent a jumpy page */
html { overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted. you'll have to restore the bullets within content, which is fine because they're probably customized anyway */
ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted #000; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse: collapse; border-spacing: 0; }

th { font-weight: bold; vertical-align: bottom; }

td { font-weight: normal; vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

input, select { vertical-align: middle; }

pre { white-space: pre; /* CSS2 */ white-space: pre-wrap; /* CSS 2.1 */ white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */ word-wrap: break-word; /* IE */ }

input[type="radio"] { vertical-align: text-bottom; }

input[type="checkbox"] { vertical-align: bottom; }

.ie7 input[type="checkbox"] { vertical-align: baseline; }

.ie6 input { vertical-align: text-bottom; }

select, input, textarea { font: 99% sans-serif; }

table { font-size: inherit; font: 100%; }

small { font-size: 85%; }

strong { font-weight: bold; }

td, td img { vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup { font-size: 75%; line-height: 0; position: relative; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp { font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable, label, input[type=button], input[type=submit], input[type=file], button { cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea { margin: 0; }

/* make buttons play nice in IE */
button, input[type=button] { width: auto; overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img { -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover (commented out as usage is rare, and the filter syntax messes with some pre-processors) .ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));} */
/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after { clear: both; }

.clearfix { zoom: 1; }

/* 設定ファイル読み込み */
/**********************
common
**********************/
*, ::after, ::before { box-sizing: border-box; list-style: none; text-decoration: none; }

html { font-size: 62.5%; -webkit-text-size-adjust: 100%; }

@media screen and (max-width: 768px) { html.active { height: 100%; overflow: hidden; } }

body { color: #333; font-family: "Noto Sans JP", “游ゴシック“, YuGothic, “Yu Gothic”, "ヒラギノ角ゴ ProN W3", “Hiragino Kaku Gothic ProN”, Arial, “メイリオ“, Meiryo, sans-serif; font-size: 1.6rem; font-weight: 400; width: 100%; }

@media screen and (max-width: 768px) { body { font-size: 1.4rem; min-width: 320px; overflow: hidden; } }

img { vertical-align: bottom; }

.pc { display: block; }

@media screen and (max-width: 768px) { .pc { display: none; } }

.sp { display: none; }

@media screen and (max-width: 768px) { .sp { display: block; } }

.cmn-inner { margin: 0 auto; max-width: 1320px; min-width: 1320px; padding: 0 60px; width: 100%; }

@media screen and (max-width: 768px) { .cmn-inner { margin: 0 auto; max-width: 375px; min-width: 100%; padding: 0 15px; } }

.cmn-ttl-s_home { color: #00b5c5; font-size: 2.7rem; font-weight: bold; margin-bottom: 30px; text-align: center; }

@media screen and (max-width: 768px) { .cmn-ttl-s_home { font-size: 1.8rem; margin-bottom: 15px; } }

.cmn-cont-wrap { display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; }

@media screen and (max-width: 768px) { .cmn-cont-wrap { flex-wrap: nowrap; flex-direction: column; justify-content: center; } }

.cmn-width50 { width: calc(50% - 60px / 2); }

@media screen and (max-width: 768px) { .cmn-width50 { width: 100%; } }

.cmn-link-wrap { background: #00b5c5; border: 1px solid #00b5c5; margin: 0 auto; width: 380px; border-radius: 30px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-wrap { width: 300px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .cmn-link-wrap { width: 100%; } }

.cmn-link-wrap:hover { background: #fff; border: 1px solid #00b5c5; }

.cmn-link-wrap_color_orange { background: #ff7800; border: 2px solid #ff7800; transition: all  0.3s ease; }

.cmn-link-wrap_color_orange:hover { background: #fff; border: 2px solid #ff7800; }

.cmn-link { color: #fff; display: block; font-size: 1.8rem; font-weight: bold; letter-spacing: 0.08em; padding: 16.5px 0; position: relative; text-align: center; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link { font-size: 1.4rem; padding: 15px 0; } }

.cmn-link:hover { color: #00b5c5; }

.cmn-link::before { background: #fff; content: ''; position: absolute; top: 30px; right: 50px; width: 25px; height: 1px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link::before { top: 24px; right: 30px; width: 18px; } }

.cmn-link:hover::before { background: #00b5c5; right: 45px; }

@media screen and (max-width: 768px) { .cmn-link:hover::before { right: 25px; } }

.cmn-link::after { content: ''; position: absolute; top: calc(50% - 3px); right: 49px; width: 10px; height: 0px; border: 1px solid; border-color: #fff transparent transparent transparent; transform: rotate(45deg); transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link::after { right: 29px; width: 8px; } }

.cmn-link:hover::after { border-color: #00b5c5 transparent transparent transparent; right: 44px; }

@media screen and (max-width: 768px) { .cmn-link:hover::after { right: 24px; } }

.cmn-link_color_orange { position: relative; transition: all  0.3s ease; }

.cmn-link_color_orange:hover { color: #ff7800; }

.cmn-link_color_orange::before { content: ''; background: url(./../img/top/icon_tel02.png) no-repeat; display: inline-block; position: absolute; top: 21px; left: 75px; width: 16px; height: 20px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link_color_orange::before { top: 16px; left: 55px; } }

.cmn-link_color_orange:hover::before { content: ''; background: url(./../img/top/icon_tel02_yellow.png) no-repeat; }

.cmn-link_color_orange::after { content: none; }

.cmn-link-wrap-fff { background: #fff; border: 2px solid #00b5c5; margin: 0 auto; width: 100%; border-radius: 30px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-wrap-fff { width: calc(100% - 30px); } }

@media screen and (max-width: 768px) and (max-width: 320px) { .cmn-link-wrap-fff { width: calc(100% - 30px); } }

.cmn-link-wrap-fff:hover { background: #00b5c5; border: 2px solid #00b5c5; }

.cmn-link-fff { color: #00b5c5; display: block; font-size: 1.8rem; font-weight: bold; letter-spacing: 0.08em; padding: 16.5px 0; position: relative; text-align: center; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-fff { font-size: 1.4rem; padding: 15px 0; } }

.cmn-link-fff:hover { color: #fff; }

.cmn-link-fff::before { background: #00b5c5; content: ''; position: absolute; top: 30px; right: 50px; width: 25px; height: 1px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-fff::before { top: 24px; right: 30px; width: 18px; } }

.cmn-link-fff:hover::before { background: #fff; right: 45px; }

@media screen and (max-width: 768px) { .cmn-link-fff:hover::before { right: 25px; } }

.cmn-link-fff::after { content: ''; position: absolute; top: calc(50% - 3px); right: 49px; width: 10px; height: 0px; border: 1px solid; border-color: #00b5c5 transparent transparent transparent; transform: rotate(45deg); transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-fff::after { right: 29px; width: 8px; } }

.cmn-link-fff:hover::after { border-color: #fff transparent transparent transparent; right: 44px; }

@media screen and (max-width: 768px) { .cmn-link-fff:hover::after { right: 24px; } }

.cmn-link-wrap-s { border-radius: 32.5px; border: 2px solid #ff7800; margin: 0 auto; width: 300px; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .cmn-link-wrap-s { width: 280px; } }

.cmn-link-wrap-s:hover { background: #ff7800; border: 2px solid #ff7800; }

.cmn-link-s { color: #ff7800; display: block; font-size: 1.4rem; font-weight: bold; letter-spacing: 0.08em; padding: 7px 0; text-align: center; transition: all  0.3s ease; }

.cmn-link-s:hover { color: #fff; }

.cmn-mv-under { min-width: 1320px; position: relative; width: 100%; z-index: 1; }

@media screen and (max-width: 768px) { .cmn-mv-under { min-width: 100%; } }

.cmn-mv-under::after { content: ''; background: rgba(0, 0, 0, 0.6); display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2; }

.cmn-inner-mv { display: flex; justify-content: center; align-items: center; height: 500px; }

@media screen and (max-width: 768px) { .cmn-inner-mv { height: 320px; } }

.cmn-catch-under { color: #fff; font-size: 5.4rem; font-weight: 900; letter-spacing: 0.4em; position: relative; z-index: 3; }

@media screen and (max-width: 768px) { .cmn-catch-under { font-weight: 600; letter-spacing: 0.2em; font-size: 3.6rem; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .cmn-catch-under { font-size: 3.2rem; } }

.cmn-catch-under::before { background: #fff; box-shadow: 2px 0px 10px #000; content: ''; height: 4px; max-width: 120px; position: absolute; top: -60px; left: 0; width: 100%; z-index: 3; }

.cmn-catch-under::after { background: #fff; box-shadow: 2px 0px 10px #000; content: ''; height: 4px; max-width: 120px; position: absolute; bottom: -60px; right: 24px; width: 100%; z-index: 3; }

.cmn-catch-s { display: block; font-size: 3.2rem; font-weight: 900; letter-spacing: 0.4em; text-align: center; }

@media screen and (max-width: 768px) { .cmn-catch-s { font-size: 2.2rem; } }

.cmn-msg { min-height: 380px; }

@media screen and (max-width: 768px) { .cmn-msg { min-height: 280px; } }

.cmn-msg_second { border-radius: 10px 0px 0px 10px; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .cmn-msg_second { border-radius: 10px 10px 10px 10px; margin-bottom: 20px; } }

.cmn-msg_first { border-radius: 0px 10px 10px 0px; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .cmn-msg_first { border-radius: 10px 10px 10px 10px; margin-bottom: 5px; } }

.examination__msg-txt { margin-bottom: 15px; }

@media screen and (max-width: 768px) { .examination__msg-txt { margin-bottom: 10px; } }

.examination__msg-list { width: 100%; }

.examination__msg-item { font-weight: 600; display: flex; font-size: 1.6rem; }

.examination__msg-item:before { content: "＊"; color: #00b5c5; margin-right: .25em; display: block; }

@media screen and (max-width: 768px) { .examination__msg-item { font-size: 1.4rem; } }

.cmn-flex-wrap { display: flex; width: 100%; height: 100%; background: #fff; border: 1px solid #fff; border-radius: 10px; font-size: 1.4rem; line-height: 1.6; padding: 30px 50px; width: 100%; text-align: left; flex-wrap: wrap; }

@media screen and (max-width: 768px) { .cmn-flex-wrap { padding: 15px; height: auto; } }

.flex-inner { display: flex; flex-wrap: wrap; width: 100%; }

.cmn-msg { display: flex; min-height: 285px; flex-direction: column; padding: 40px; width: 50%; }

@media screen and (max-width: 768px) { .cmn-msg { padding: 10px; min-height: auto; width: 100%; } }

.cmn-msg_first { background: #eaf7fb; border: 1px solid #eaf7fb; border-radius: 10px 0px 0px 10px; }

@media screen and (max-width: 768px) { .cmn-msg_first { border-radius: 8px; margin-bottom: 20px; } }

.cmn-msg_second { background: #d3ecf6; border: 1px solid #d3ecf6; border-radius: 0px 10px 10px 0px; }

@media screen and (max-width: 768px) { .cmn-msg_second { border-radius: 8px; } }

@media screen and (max-width: 768px) { .cmn-msg-txt { border-radius: 8px; font-size: 1.2rem; padding: 0 5px; text-align: left; } }

.cmn-item-under { display: flex; width: 100%; }

@media screen and (max-width: 768px) { .cmn-item-under { height: auto; } }

.cmn-item-under .cmn-flex-wrap { height: auto; }

.cmn-item-under:nth-of-type(odd) .cmn-flex-wrap { border-radius: 0 20px 20px 0; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-of-type(odd) .cmn-flex-wrap { border-radius: 0 0 8px 8px; } }

.cmn-item-under:nth-of-type(even) .cmn-flex-wrap { border-radius: 20px 0 0 20px; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-of-type(even) .cmn-flex-wrap { border-radius: 0 0 8px 8px; } }

.cmn-item-under:not(:last-of-type) { margin-bottom: 50px; }

@media screen and (max-width: 768px) { .cmn-item-under:not(:last-of-type) { margin-bottom: 25px; } }

.cmn-item-under:nth-child(odd) { flex-direction: row-reverse; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(odd) { flex-direction: column-reverse; } }

.cmn-item-under:nth-child(odd) .cmn-txt-wrap { border-radius: 0 20px 20px 0; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(odd) .cmn-txt-wrap { border-radius: 0 0 8px 8px; } }

.cmn-item-under:nth-child(odd) .cmn-img { border-radius: 20px 0 0 20px; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(odd) .cmn-img { border-radius: 8px 8px 0 0; } }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(even) { flex-direction: column-reverse; } }

.cmn-item-under:nth-child(even) .cmn-txt-wrap { border-radius: 20px 0 0 20px; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(even) .cmn-txt-wrap { border-radius: 0 0 8px 8px; } }

.cmn-item-under:nth-child(even) .cmn-img { border-radius: 0 20px 20px 0; }

@media screen and (max-width: 768px) { .cmn-item-under:nth-child(even) .cmn-img { border-radius: 8px 8px 0 0; } }

.cmn-txt-wrap { background: #fff; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; }

@media screen and (max-width: 768px) { .cmn-txt-wrap { min-height: 100%; min-width: 100%; width: 100%; } }

.cmn-item-desc { font-size: 1.6rem; letter-spacing: 0.08em; line-height: 1.5; width: 100%; }

@media screen and (max-width: 768px) { .cmn-item-desc { letter-spacing: 0.02em; } }

.cmn-img-wrap { min-height: 380px; min-width: 600px; width: 50%; }

@media screen and (max-width: 768px) { .cmn-img-wrap { min-height: 100%; min-width: 100%; width: 100%; } }

.cmn-img { width: 100%; }

.cmn-sec-ttl-l { font-size: 3.2rem; font-weight: 700; letter-spacing: 0.2em; margin-bottom: 80px; text-align: center; position: relative; }

@media screen and (max-width: 768px) { .cmn-sec-ttl-l { font-size: 2.1rem; margin-bottom: 40px; } }

.cmn-sec-ttl-l::before { background: #00b5c5; content: ''; height: 3px; position: absolute; bottom: -30px; right: 50%; width: 20px; }

@media screen and (max-width: 768px) { .cmn-sec-ttl-l::before { bottom: -15px; } }

.cmn-sec-ttl-l::after { background: #0395a2; content: ''; height: 3px; position: absolute; left: 50%; bottom: -30px; width: 20px; }

@media screen and (max-width: 768px) { .cmn-sec-ttl-l::after { bottom: -15px; } }

.cmn-sec-ttl-m { background: #00b5c5; border-radius: 10px; color: #fff; display: flex; align-items: center; font-size: 2.7rem; font-weight: 700; min-height: 65.84px; letter-spacing: 0.08em; margin-bottom: 28px; padding-left: 27px; }

@media (-ms-high-contrast: none) { .cmn-sec-ttl-m { /* IE10/11 に適用 */ height: 65.84px; } }

@media screen and (max-width: 768px) { .cmn-sec-ttl-m { font-size: 1.8rem; border-radius: 5px; padding: 10px 15px; margin-bottom: 15px; } }

@media screen and (max-width: 768px) and (-ms-high-contrast: none) { .cmn-sec-ttl-m { /* IE10/11 に適用 */ height: 60px; } }

@media screen and (max-width: 768px) { .cmn-sec-ttl-m .sp { display: none; } }

@media screen and (max-width: 567px) { .cmn-sec-ttl-m .sp { display: block; } }

.cmn-sec-ttl-s { color: #00b5c5; font-size: 2.4rem; font-weight: 700; letter-spacing: 0.08em; line-height: 1.5; }

@media screen and (max-width: 768px) { .cmn-sec-ttl-s { font-size: 1.8rem; letter-spacing: normal; } }

.cmn-msg-ttl { color: #00b5c5; font-size: 2.7rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 25px; text-align: center; }

@media screen and (max-width: 768px) { .cmn-msg-ttl { font-size: 1.8rem; margin-bottom: 8px; } }

.cmn-msg-ttl .se { display: none; }

@media screen and (max-width: 320px) { .cmn-msg-ttl .se { display: block; } }

.cmn-item-ttl { color: #00b5c5; font-size: 2.4rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 30px; text-align: center; }

@media screen and (max-width: 768px) { .cmn-item-ttl { font-size: 1.8rem; letter-spacing: 0.02em; margin-bottom: 15px; } }

.cmn-news__item { border-bottom: 1px solid #dddddd; }

.cmn-news__item:not(:last-of-type) { margin-bottom: 20px; }

@media screen and (max-width: 768px) { .cmn-news__item:not(:last-of-type) { margin-bottom: 10px; } }

.cmn-news__item-link { color: #333; display: flex; font-size: 1.6rem; font-weight: 600; letter-spacing: 0.08em; padding: 0 0 20px; }

@media screen and (max-width: 768px) { .cmn-news__item-link { font-size: 1.4rem; padding: 0 0 10px; } }

.cmn-news__time { color: #696969; font-size: 1.6rem; font-weight: 600; letter-spacing: 0.08em; margin-right: 20px; }

@media screen and (max-width: 768px) { .cmn-news__time { font-size: 1.4rem; } }

/**********************
header
**********************/
.header { background: #fff; min-width: 1320px; position: fixed; top: 0; width: 100%; z-index: 5; }

@media screen and (max-width: 768px) { .header { background-color: #fff; min-width: 100%; min-height: 51px; width: 100%; } }

@media screen and (max-width: 768px) and (-ms-high-contrast: none) { .header { /* IE10/11 に適用 */ height: 51px; } }

@media screen and (max-width: 768px) { .header.active { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 5; overflow: scroll; } }

@media screen and (max-width: 768px) { .header.active .header__top { height: auto; } }

.header__top { display: flex; flex-wrap: wrap; justify-content: space-between; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .header__top { flex-direction: column; min-width: 100%; padding: 0; position: relative; height: inherit; } }

.header__logo { font-size: 2.8rem; font-weight: bold; letter-spacing: 0.1em; padding: 30px 0 30px 60px; transition: all ease .3s; width: 320px; list-style: 1; }

.header__logo-sm { display: block; font-size: 1.6rem; }

@media screen and (max-width: 768px) { .header__logo-sm { font-size: 1.2rem; } }

@media screen and (min-width: 1320px) { .header__logo { margin-left: calc((100vw - 1320px) / 2); } }

@media screen and (max-width: 768px) { .header__logo { font-size: 1.8rem; order: 0; padding: 9px 0 9px 20px; width: 140px; } }

.header__logo.padding-non { padding: 10px 0 10px 60px; }

@media screen and (max-width: 768px) { .header__logo.padding-non { font-size: 2.2rem; order: 0; padding: 9px 0 0 20px; width: 140px; } }

.header__logo-link { color: #000; }

.header__logo-link:hover { opacity: 0.7; }

.header__right-con { align-items: center; display: flex; justify-content: flex-end; padding: 30px 60px 30px 0; transition: all ease .3s; width: 840px; }

@media screen and (min-width: 1320px) { .header__right-con { margin-right: calc((100vw - 1320px) / 2); } }

@media screen and (max-width: 768px) { .header__right-con { background-color: #fff; display: none; flex-direction: column; opacity: 0; order: 3; padding-right: 0; padding-left: 0; padding-bottom: 90px; transform: translateX(40px); transition: .5s; -webkit-overflow-scrolling: touch; visibility: hidden; width: 100%; } }

.header__right-con.padding-non { padding: 10px 60px 10px 0px; }

@media screen and (max-width: 768px) { .header__right-con.padding-non { padding: 20px 0 60px 0; } }

.header__right-con.is_show { display: flex; }

@media screen and (max-width: 768px) { .header.active .header__right-con { opacity: 1; transform: translateX(0); visibility: visible; } }

.header__f-control { font-size: 1.5rem; margin-right: 30px; }

@media screen and (max-width: 768px) { .header__f-control { margin: 0 0 20px; width: 100%; font-size: 1.4rem; } }

.header__dl { display: flex; justify-content: space-between; }

@media screen and (max-width: 768px) { .header__dl { flex-wrap: wrap; justify-content: center; } }

.header__dt { font-size: 1.5rem; letter-spacing: 0.08em; margin-right: 15px; padding: 6px 0; }

@media screen and (max-width: 768px) { .header__dt { font-size: 1.4rem; margin-right: 0; text-align: center; width: 100%; } }

.header__dd { background: #ddd; font-size: 1.5rem; font-weight: bold; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .header__dd { font-size: 1.4rem; } }

.header__dd.active { background: #00b5c5; }

.header__middle { border-radius: 4px 0px 0px 4px; }

.header__large { border-radius: 0px 4px 4px 0px; }

.header__btn { color: #fff; display: inline-block; padding: 6px 20px; }

.header__list { display: flex; margin-right: 30px; }

@media screen and (max-width: 768px) { .header__list { justify-content: center; margin: 0 0 20px; width: 100%; } }

.header__item:first-of-type { margin-right: 15px; }

.header__link { color: #333; display: block; font-size: 1.5rem; letter-spacing: 0.08em; padding-left: 30px; position: relative; }

.header__link:hover { opacity: 0.7; }

.header__link_home-access::before { content: ''; background: url(./../img/common/icon_car02.png) no-repeat; display: inline-block; position: absolute; top: 3px; left: 0; width: 27px; height: 16px; }

.header__link_reservation { padding-left: 25px; }

.header__link_reservation::before { content: ''; background: url(./../img/common/icon_book02.png) no-repeat; display: inline-block; position: absolute; top: 3px; left: 0; width: 22px; height: 16px; }

@media screen and (max-width: 768px) { .header__tel-wrap { width: 100%; } }

.header__tel-link { color: #333; }

.header__tel { font-size: 2.2rem; font-weight: bold; letter-spacing: 0.14em; padding-left: 33px; position: relative; }

@media screen and (max-width: 768px) { .header__tel { margin: 0 0 8px; padding: 0; text-align: center; } }

.header__tel::before { content: ''; background: url(./../img/common/icon_tel01.png) no-repeat; display: inline-block; position: absolute; top: 4px; left: 0; width: 26px; height: 22px; }

@media screen and (max-width: 768px) { .header__tel::before { content: none; } }

.header__hours { font-size: 1.4rem; letter-spacing: 0.04em; line-height: 1.4; }

@media screen and (max-width: 768px) { .header__hours { text-align: center; } }

@media screen and (max-width: 768px) { .header__bdr { display: inline-block; cursor: pointer; height: 22px; position: absolute; top: 12px; right: 20px; transition: all ease .7s; vertical-align: middle; width: 30px; z-index: 5; }
  .header__bdr:after { content: 'メニュー'; display: inline-block; font-size: 10px; position: absolute; letter-spacing: -0.2em; top: 18px; left: -2px; width: calc(100% + 3px); } }

@media screen and (max-width: 768px) { .header__bdr span, .header__bdr span:before, .header__bdr span:after { background: #555; border-radius: 3px; content: ''; display: block; height: 3px; position: absolute; transition: all .5s; width: 30px; } }

@media screen and (max-width: 768px) { .header__bdr span:before { bottom: -6px; } }

@media screen and (max-width: 768px) { .header__bdr span:after { bottom: -12px; } }

@media screen and (max-width: 768px) { .header.active .header__bdr span { position: absolute; top: 0; transform: translateY(8px) rotate(-45deg); } }

@media screen and (max-width: 768px) { .header.active .header__bdr span:before { opacity: 0; } }

@media screen and (max-width: 768px) { .header.active .header__bdr span:after { bottom: -16px; transform: translateY(-15px) rotate(-90deg); } }

.header.active .header__bdr:after { top: 22px; content: "閉じる"; left: 3px; }

/********** nav **********/
@media screen and (max-width: 768px) { .header.active .nav { opacity: 1; transform: translateX(0); visibility: visible; z-index: 5; } }

.nav { background-color: #00b5c5; font-size: 1.8rem; width: 100%; }

@media screen and (max-width: 768px) { .nav { display: none; margin-top: 10px; opacity: 0; order: 2; transform: translateX(40px); transition: .5s; visibility: hidden; } }

.nav.is_show { display: block; }

.nav__inner { margin: 0 auto; max-width: 1320px; padding: 0 60px; width: 100%; }

@media screen and (max-width: 768px) { .nav__inner { max-width: 100%; padding: 0; } }

.nav__list { display: flex; justify-content: space-between; }

@media screen and (max-width: 768px) { .nav__list { flex-direction: column; } }

.nav__item { border-right: 1px solid #0395a2; position: relative; width: 16.666%; }

@media screen and (max-width: 768px) { .nav__item { border-bottom: 1px dotted #0395a2; border-right: none; width: 100%; } }

.nav__item:first-of-type { border-left: 1px solid #0395a2; }

@media screen and (max-width: 768px) { .nav__item:first-of-type { border-left: none; } }

.nav__link { color: #fff; display: block; font-size: 1.8rem; font-weight: bold; letter-spacing: 0.08em; padding: 16.5px 15px; text-align: center; transition: all  0.3s ease; }

@media screen and (max-width: 768px) { .nav__link { display: flex; font-size: 1.4rem; text-align: left; padding: 16.5px 20px; }
  .nav__link.next { position: relative; }
  .nav__link.next::after { content: ''; display: inline-block; position: absolute; top: 23px; right: 23px; margin-left: auto; content: ""; vertical-align: middle; width: 8px; height: 8px; border-top: 2px solid #fff; border-right: 2px solid #fff; transform: rotate(45deg); }
  .nav__link.more { position: relative; }
  .nav__link.more::before, .nav__link.more::after { content: ''; position: absolute; display: inline-block; width: 10px; height: 10px; border-top: 2px solid #fff; }
  .nav__link.more::before { top: 27px; right: 15px; transform: translateX(-50%); }
  .nav__link.more::after { top: 23px; right: 24px; transform: rotate(90deg); }
  .nav__link.active::after { display: none; } }

.nav__list_sub { transition: all .5s ease-out; position: absolute; width: 100%; z-index: 10; transition: .5s; overflow: hidden; opacity: 0; visibility: hidden; transform: translateY(-10px); }

@media screen and (min-width: 769px) { .nav__list_sub { display: block !important; } }

@media screen and (max-width: 768px) { .nav__list_sub { display: none; position: static; transition: .0s; overflow: visible; opacity: 1; visibility: visible; transform: translateY(0); } }

.nav__link:hover, .nav__link_sub:hover { opacity: 0.3; }

.nav__item:hover > .nav__list_sub { display: block; opacity: 1; visibility: visible; transform: translateY(0); }

@media screen and (max-width: 768px) { .nav__item:hover > .nav__list_sub { display: none; } }

.nav__item_sub { background: #00b5c5; border-top: 1px solid #0395a2; }

@media screen and (max-width: 768px) { .nav__item_sub { border-top: 1px dotted #0395a2; } }

.nav__link_sub { color: #fff; display: block; font-size: 1.6rem; font-weight: bold; letter-spacing: 0.08em; padding: 16.5px 15px; text-align: center; transition: all 0.3s ease; }

@media screen and (max-width: 768px) { .nav__link_sub { font-size: 1.4rem; text-align: left; padding: 16.5px 20px 16.5px 40px; }
  .nav__link_sub.next { position: relative; }
  .nav__link_sub.next::after { content: ''; display: inline-block; position: absolute; top: 23px; right: 23px; margin-left: auto; content: ""; vertical-align: middle; width: 8px; height: 8px; border-top: 2px solid #fff; border-right: 2px solid #fff; transform: rotate(45deg); } }

/**********************
breadcrumbs
**********************/
.breadcrumbs { background: #eaf7fb; margin-top: 172px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .breadcrumbs { margin-top: 50px; min-width: 100%; } }

.breadcrumbs__list { display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: center; min-height: 44px; }

@media (-ms-high-contrast: none) { .breadcrumbs__list { /* IE10/11 に適用 */ height: 44px; } }

@media screen and (max-width: 768px) { .breadcrumbs__list { min-height: 100%; flex-wrap: nowrap; overflow-x: auto; white-space: nowrap; } }

.breadcrumbs__item { margin-right: 6px; }

@media screen and (max-width: 768px) and (max-width: 320px) { .breadcrumbs__item { margin-right: 0px; } }

.breadcrumbs__item:nth-child(n+2) { display: flex; }

@media screen and (max-width: 768px) { .breadcrumbs__item:nth-child(3) { margin: 0 0 0 10px; } }

.breadcrumbs__item:nth-child(n+2)::before { content: ''; box-sizing: border-box; border: 5px solid transparent; border-left: 5px solid #333; display: inline-block; height: 5px; width: 5px; vertical-align: middle; margin: 10px 0 0; }

.breadcrumbs__link { color: #333; display: inline-block; font-size: 14px; letter-spacing: 0.1em; padding: 6px 10px; position: relative; }

@media screen and (max-width: 768px) { .breadcrumbs__link { font-size: 12px; } }

.breadcrumbs__link::after { position: absolute; bottom: 3px; left: 0; content: ''; width: 100%; height: 2px; background: #333; transform: scale(0, 1); transform-origin: right top; transition: transform .3s; }

.breadcrumbs__link:hover::after { transform-origin: left top; transform: scale(1, 1); }

/**********************
main-contents
**********************/
.main-contents { display: block; }

/**********************
other-sec
**********************/
.other-sec { text-align: center; }

.other-sec__info { font-size: 2.7rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 22px; }

@media screen and (max-width: 768px) { .other-sec__info { font-size: 1.8rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 20px; } }

.other-sec__link-wrap { display: inline-block; }

.other-sec__link-wrap:not(:last-of-type) { margin: 0 60px 0 0; }

@media screen and (max-width: 768px) { .other-sec__link-wrap:not(:last-of-type) { margin: 0 0 10px 0; } }

/**********************
map
**********************/
.map { text-align: center; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .map { min-width: 100%; } }

.map__iframe { border: none; height: calc(450/1400*100vw); width: 100%; }

@media screen and (max-width: 768px) { .map__iframe { height: calc(450/375*50vw); } }

/**********************
footer
**********************/
.footer { padding: 0 60px; min-width: 1320px; width: 100%; overflow: hidden; }

@media screen and (max-width: 768px) { .footer { padding: 0; min-width: 100%; } }

.footer__top { display: flex; justify-content: space-between; align-items: center; width: 1000px; max-width: 1000px; margin-left: auto; margin-right: auto; padding: 70px 0; }

@media screen and (max-width: 768px) { .footer__top { padding: 25px 15px; flex-direction: column-reverse; width: 100%; max-width: 100%; } }

@media screen and (max-width: 768px) { .footer__company-wrap { display: flex; flex-direction: column; text-align: center; width: 100%; } }

.footer__logo { font-size: 2.8rem; font-weight: bold; letter-spacing: 0.1em; margin: 0 0 16px; width: 200px; }

.footer__logo-sm { font-size: 1.6rem; display: block; }

@media screen and (max-width: 768px) { .footer__logo-sm { font-size: 1.0rem; display: block; } }

@media screen and (max-width: 768px) { .footer__logo { margin: 0 auto 16px; width: 140px; font-size: 2.0rem; } }

.footer__logo-link { color: #000; display: inline-block; }

.footer__logo-link:hover { opacity: 0.7; }

.footer__add { font-size: 1.6rem; line-height: 1.5; }

@media screen and (max-width: 768px) { .footer__add { font-size: 1.4rem; } }

.footer__tel { font-size: 1.6rem; letter-spacing: 0.075em; line-height: 1.5; margin-bottom: 20px; }

@media screen and (max-width: 768px) { .footer__tel { font-size: 1.4rem; } }

.footer__tel-color { color: #00b5c5; display: inline-block; font-size: 2.4rem; letter-spacing: 0.075em; }

@media screen and (max-width: 768px) { .footer__tel-color { display: inline-block; font-size: 2.2rem; } }

@media screen and (max-width: 768px) { .footer__tel-color[href*="tel:"] { pointer-events: none; cursor: default; text-decoration: none; } }

.footer__link-wrap { width: 244px; }

.footer__biz-hours.biz-hours { width: inherit; }

@media screen and (max-width: 768px) { .footer__biz-hours.biz-hours { width: 100%; } }

.footer__biz-hours .biz-hours__table { margin: 0 0 5px auto; }

@media screen and (max-width: 768px) { .footer__biz-hours .biz-hours__table { margin: 0 auto 5px; width: 100%; } }

.footer__biz-hours .biz-hours__reception { margin: 0 0 0 auto; width: 560px; }

@media screen and (max-width: 768px) { .footer__biz-hours .biz-hours__reception { margin: 0 auto 40px; width: 100%; } }

.footer__cr { background: #00b5c5; color: #fff; display: block; font-size: 1.2rem; text-align: center; padding: 20px 0; margin: 0 -200px; }

@media screen and (max-width: 768px) { .footer__cr { font-size: 1rem; margin: 0; width: 100%; } }

.layer_board_bg { position: fixed; width: 100%; height: 100%; z-index: 10; top: 0; left: 0; display: none; cursor: pointer; background: #333; }

.layer_board { cursor: pointer; position: fixed; height: 100%; width: 100%; top: 20%; left: 0; z-index: 10; display: none; }

@media screen and (max-width: 768px) { .layer_board { top: 20%; } }

.modalContent { display: flex; align-items: center; justify-content: center; }

.modalContent .modalContent__inner { background-color: #fff; max-width: 790px; padding: 55px 100px; width: 100%; }

@media screen and (max-width: 768px) { .modalContent .modalContent__inner { padding: 20px; } }

.modalContent__ttl { font-size: 2.2rem; margin-bottom: 25px; text-align: center; }

@media screen and (max-width: 768px) { .modalContent__ttl { font-size: 1.8rem; } }

.modalContent__note-wrap { margin-bottom: 25px; }

.modalContent__note { color: #ff7800; font-size: 1.4rem; margin-bottom: 5px; text-align: center; }

@media screen and (max-width: 768px) { .modalContent__note { font-size: 1.2rem; } }

.modalContent__txt { font-size: 1.6rem; margin-bottom: 25px; text-align: center; }

@media screen and (max-width: 768px) { .modalContent__txt { font-size: 1.2rem; } }

.modal__tel { color: #333; font-size: 2.2rem; }

@media screen and (max-width: 768px) { .modal__tel { font-size: 1.8rem; } }

.modal__close { cursor: pointer; height: 100px; margin-left: auto; position: relative; top: -500px; right: 0; width: 100px; z-index: 11; }

@media screen and (max-width: 768px) { .modal__close { top: -400px; right: -10px; } }

.modal__close:before { transform: rotate(45deg); }

.modal__close:after { transform: rotate(-45deg); }

.modal__close:before, .modal__close:after { border-top: 2px solid #ccc; content: ""; display: block; height: 0; position: absolute; top: 40px; right: 30%; width: 35px; }

.link-box { display: flex; justify-content: space-between; }

.cmn-link-wrap.modalIn { width: 240px; }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn { width: 200px; } }

@media screen and (max-width: 768px) and (max-width: 568px) { .cmn-link-wrap.modalIn { width: 135px; } }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn .cmn-link { padding: 10px 0; } }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn .cmn-link::before { top: 20px; right: 18px; } }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn .cmn-link:hover::before { right: 14px; } }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn .cmn-link::after { top: 18px; right: 16px; } }

@media screen and (max-width: 768px) { .cmn-link-wrap.modalIn .cmn-link:hover::after { right: 13px; } }

.cmn-link-wrap.btn_gray { background: gray; border: 1px solid gray; }

.cmn-link-wrap.btn_gray:hover { background: #fff; border: 1px solid gray; }

.cmn-link-wrap.btn_gray .cmn-link:hover { color: gray; }

.cmn-link-wrap.btn_gray .cmn-arrow:hover::before { background: gray; }

.cmn-link-wrap.btn_gray .cmn-arrow:hover::after { border-color: gray transparent transparent transparent; }

.under-subsec { padding-top: 47px; padding-bottom: 80px; min-width: 1320px; }

.under-subsec_bgb { background: linear-gradient(to right, #eaf7fb, #d3ecf6); }

@media screen and (max-width: 768px) { .under-subsec { min-width: 100%; } }

/* 設定ファイル読み込み */
/**********************
home
**********************/
.home__sec { display: flex; justify-content: center; align-items: center; max-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .home__sec { flex-direction: column-reverse; min-width: 100%; } }

.home__txt-wrap { display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 0 68px; width: 600px; }

@media screen and (max-width: 768px) { .home__txt-wrap { padding: 0 14px; width: 100%; } }

.home__desc { font-size: 1.8rem; text-align: center; line-height: 2.0; margin-bottom: 40px; width: 100%; }

@media screen and (max-width: 768px) { .home__desc { font-size: 1.4rem; text-align: left; margin-bottom: 25px; } }

.home__img-wrap { width: 600px; }

@media screen and (max-width: 768px) { .home__img-wrap { width: 100%; } }

/**********************
mv
**********************/
.mv { background: url(./../img/top/img_key04.jpg) no-repeat center center/cover; height: 560px; margin-top: 171px; min-width: 1320px; position: relative; width: 100%; z-index: 1; }

@media screen and (max-width: 768px) { .mv { margin-top: 50px; min-width: 100%; height: 320px; } }

.mv__inner { display: flex; flex-direction: column; align-items: center; padding-top: 200px; }

@media screen and (max-width: 768px) { .mv__inner { flex-direction: inherit; flex-wrap: wrap; align-content: center; padding-top: 0; height: 100%; padding-top: 1em; } }

.mv__catch { color: #fff; font-size: 7.0rem; font-weight: 600; letter-spacing: 0.08em; margin-bottom: 86px; text-shadow: 0px 0px 10px #000, 0px 0px 18px #777; }

@media screen and (max-width: 768px) { .mv__catch { font-size: 3.2rem; margin-bottom: 25px; position: relative; } }

@media screen and (max-width: 320px) { .mv__catch { font-size: 3.2rem; } }

.mv__catch::before { background: #fff; box-shadow: 2px 0px 10px #000; content: ''; height: 4px; max-width: 760px; position: absolute; top: 30%; left: 0; width: 100%; }

@media screen and (max-width: 768px) { .mv__catch::before { max-width: 260px; top: -.5em; } }

.mv__catch::after { background: #fff; box-shadow: 2px 0px 10px #000; content: ''; height: 4px; max-width: 760px; position: absolute; bottom: 42%; right: 0; width: 100%; }

@media screen and (max-width: 768px) { .mv__catch::after { max-width: 260px; bottom: -.5em; } }

.mv__text { color: #fff; font-size: 3.2rem; font-weight: 600; letter-spacing: 0.08em; text-shadow: 0px 0px 10px #000, 0px 0px 18px #777; }

@media screen and (max-width: 768px) { .mv__text { font-size: 1.5rem; } }

/**********************
sec-wrap
**********************/
.sec-wrap { display: flex; justify-content: space-between; padding: 55px 60px 77px 60px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .sec-wrap { flex-direction: column; padding: 55px 15px; min-width: 100%; } }

/********** home-news **********/
.home-news { margin-right: 60px; width: 100%; }

@media screen and (max-width: 768px) { .home-news { margin: 0 0 55px; min-width: 100%; width: 100%; } }

.home-news__list { min-height: 195px; padding-top: 20px; margin-bottom: 50px; }

@media screen and (max-width: 768px) { .home-news__list { min-height: 100%; padding-top: 0; margin-bottom: 20px; } }

@media screen and (max-width: 768px) { .home-news__link::before { right: 20px; } }

@media screen and (max-width: 768px) { .home-news__link:hover::before { right: 15px; } }

@media screen and (max-width: 768px) { .home-news__link::after { right: 19px; } }

@media screen and (max-width: 768px) { .home-news__link:hover::after { right: 14px; } }

/********** biz-hours **********/
.biz-hours { min-width: 560px; width: 100%; }

@media screen and (max-width: 768px) { .biz-hours { min-width: 100%; } }

.biz-hours__table { border-collapse: collapse; margin-bottom: 5px; }

@media screen and (max-width: 768px) { .biz-hours__table { margin: 0 auto 10px; width: 100%; } }

.biz-hours__tbody { width: 100%; }

.biz-hours__tbody .biz-hours__tr_week th:not(:last-of-type) { border-right: 1px solid #ddecff; }

.biz-hours__tr th, .biz-hours__tr td { font-size: 1.8rem; font-weight: 600; letter-spacing: 0.08em; padding: 16px 18px; }

@media screen and (max-width: 768px) { .biz-hours__tr th, .biz-hours__tr td { font-size: 1.4rem; padding: 10px 7px; text-align: center; } }

.biz-hours__th:first-of-type, .biz-hours__td:first-of-type { text-align: left; }

.biz-hours__tr_week, .biz-hours__tr_pm { background: #d3ecf6; }

.biz-hours__tr_am { background: #eaf7fb; }

.biz-hours__tr:not(:last-of-type) { border-bottom: 1px solid #fff; }

.biz-hours__reception { font-weight: 700; margin: 0 auto; text-align: left; width: 100%; }

@media screen and (max-width: 768px) { .biz-hours__reception { font-size: 1.4rem; margin: 0 auto 0 0; } }

@media screen and (max-width: 320px) { .biz-hours__reception { font-size: 1.2rem; } }

.biz-hours .biz-hours__para { font-size: 1.6rem; margin: 0 0 10px; width: 100%; }

@media screen and (max-width: 768px) { .biz-hours .biz-hours__para { font-size: 1.4rem; } }

/**********************
about-sec
**********************/
.about-sec { background: linear-gradient(to right, #eaf7fb, #d3ecf6); padding: 75px 0 90px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .about-sec { padding: 55px 0; min-width: 100%; } }

.about-sec__list { display: flex; justify-content: space-between; }

@media screen and (max-width: 768px) { .about-sec__list { flex-direction: column; } }

.about-sec__item { border-bottom: 5px solid #00b5c5; border-radius: 0 0 10px 10px; margin-right: 60px; width: calc(33.3333333% - 120px / 3); }

@media screen and (max-width: 768px) { .about-sec__item { margin: 0 auto 25px; max-width: 370px; min-width: auto; width: 100%; } }

.about-sec__item:last-of-type { margin-right: 0; }

@media screen and (max-width: 768px) { .about-sec__item:last-of-type { margin: 0 auto; } }

.about-sec__img { border-radius: 10px 10px 0 0; }

.about-sec__txt-wrap { background: #fff; padding: 40px 28px; text-align: center; }

@media screen and (max-width: 768px) { .about-sec__txt-wrap { padding: 20px 14px 25px; } }

.about-sec__item-ttl { border-bottom: 2px solid #00b5c5; color: #00b5c5; display: inline-block; font-size: 2.4rem; font-weight: 600; letter-spacing: 0.08em; line-height: 2.6rem; margin-bottom: 30px; padding-bottom: 2px; }

@media screen and (max-width: 768px) { .about-sec__item-ttl { font-size: 1.8rem; margin-bottom: 15px; } }

.about-sec__item-desc { color: #333; font-size: 1.6rem; letter-spacing: 0.08em; text-align: center; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .about-sec__item-desc { font-size: 1.4rem; margin-bottom: 15px; } }

.about-sec__link-wrap { width: 100%; }

.about-sec__link { display: block; }

.about-sec__link:hover .cmn-link-wrap-s { background: #ff7800; border: 2px solid #ff7800; }

.about-sec__link:hover .cmn-link-s { color: #fff; }

/**********************
visitor-sec
**********************/
.visitor-sec { padding: 90px 60px; flex-direction: row-reverse; }

@media screen and (max-width: 768px) { .visitor-sec { flex-direction: column-reverse; padding: 55px 15px; } }

.visitor-sec__ttl { display: block; }

@media screen and (max-width: 768px) { .visitor-sec__img-wrap_sp { margin-bottom: 20px; width: 100%; } }

.visitor-sec__img { border-radius: 20px 0px 0px 20px; }

@media screen and (max-width: 768px) { .visitor-sec__img { border-radius: 8px 8px 0px 0px; width: 100%; } }

/**********************
treatment-sec
**********************/
.treatment-sec { padding: 80px 0 90px; position: relative; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .treatment-sec { padding: 0 0 55px; min-width: 100%; } }

.treatment-sec::before, .treatment-sec::after { content: ''; height: 100%; position: absolute; top: 0; width: 50%; z-index: -1; }

@media screen and (max-width: 768px) { .treatment-sec::before, .treatment-sec::after { position: static; } }

.treatment-sec::before { background: #eaf7fb; right: 50%; }

.treatment-sec::after { background: #d3ecf6; left: 50%; }

.treatment-sec__list { display: flex; justify-content: space-between; }

@media screen and (max-width: 768px) { .treatment-sec__list { flex-direction: column; } }

.treatment-sec__item { max-width: 550px; width: 100%; }

@media screen and (max-width: 768px) { .treatment-sec__item { background: #d3ecf6; padding-bottom: 25px; max-width: inherit; } }

@media screen and (max-width: 768px) { .treatment-sec__item:first-of-type { background: #eaf7fb; margin: 0 0 25px; border-radius: 8px; } }

@media screen and (max-width: 768px) { .treatment-sec__item:last-of-type { border-radius: 8px; } }

@media screen and (max-width: 768px) { .treatment-sec__item:last-of-type img { border: 1px solid #eee; border-radius: 8px 8px 0 0; } }

.treatment-sec__img-wrap { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .treatment-sec__img-wrap { margin-bottom: 0; } }

.treatment-sec__img { border-radius: 8px 8px 0 0; }

@media screen and (max-width: 768px) { .treatment-sec__img { width: 100%; height: auto; } }

.treatment-sec__txt-wrap { min-height: 200px; }

@media screen and (max-width: 768px) { .treatment-sec__txt-wrap { min-height: 100%; padding: 20px 14px 0; } }

.treatment-sec__item-ttl { font-size: 2.7rem; font-weight: 600; letter-spacing: 0.08em; margin-bottom: 20px; }

@media screen and (max-width: 768px) { .treatment-sec__item-ttl { font-size: 1.6rem; margin-bottom: 5px; } }

.treatment-sec__item-desc { font-size: 1.8rem; letter-spacing: 0.08em; line-height: 2.0; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .treatment-sec__item-desc { font-size: 1.4rem; margin-bottom: 25px; } }

/**********************
department
**********************/
.department-sec { padding: 90px 60px 120px 60px; }

@media screen and (max-width: 768px) { .department-sec { padding: 0 15px 55px; } }

@media screen and (max-width: 768px) { .department-sec__img-wrap_sp { margin-bottom: 20px; width: 100%; } }

.department-sec__img { border-radius: 0px 20px 20px 0px; width: 100%; }

@media screen and (max-width: 768px) { .department-sec__img { border-radius: 8px 8px 0px 0px; } }

/* 設定ファイル読み込み */
/**********************
visitor-mv
**********************/
.visitor__mv { background: url(./../img/visitor/img_key01.jpg) no-repeat center/cover; }

/**********************
visitor-info
**********************/
.visitor-info { padding: 48px 0 90px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .visitor-info { padding: 55px 0; min-width: 100%; } }

/**********************
v-about
**********************/
.v-about__cont-wrap { margin-bottom: 60px; }

@media screen and (max-width: 768px) { .v-about__cont-wrap { margin-bottom: 30px; } }

.v-about__list { margin: 0 0 58px; }

@media screen and (max-width: 768px) { .v-about__list { margin: 0; } }

.v-about__item { border-bottom: 1px solid #ddd; padding-bottom: 10px; }

.v-about__item:not(:last-of-type) { margin-bottom: 20px; }

.v-about__item-ttl { text-align: left; margin-bottom: 5px; }

@media screen and (max-width: 768px) { .v-about__item-ttl { margin-bottom: 0; } }

.v-about__item-desc { letter-spacing: 0.2em; margin-bottom: 3px; }

@media screen and (max-width: 768px) { .v-about__item-desc { letter-spacing: 0.1em; } }

.v-about__item-note { display: block; color: #ff7800; font-size: 1.4rem; letter-spacing: 0.2em; }

@media screen and (max-width: 768px) { .v-about__item-note { font-size: 1.2rem; letter-spacing: 0.1em; } }

@media screen and (max-width: 320px) { .v-about__item-note { font-size: 1.1rem; } }

.v-about__biz-hours { display: flex; flex-direction: column; justify-content: center; align-items: flex-end; width: calc(50% - 60px / 2); }

@media screen and (max-width: 768px) { .v-about__biz-hours { margin-bottom: 55px; width: 100%; } }

.v-about__biz-hours .biz-hours__table { width: 100%; }

.v-about__biz-hours .biz-hours__tbody { width: 100%; }

.v-about__biz-hours .biz-hours__tbody .biz-hours__tr_week th:not(:last-of-type) { border-right: 1px solid #d8eef7; }

.v-about__biz-hours .biz-hours__tr th, .v-about__biz-hours .biz-hours__tr td { font-size: 1.8rem; font-weight: 600; letter-spacing: 0.08em; padding: 16px 18px; }

@media screen and (max-width: 768px) { .v-about__biz-hours .biz-hours__tr th, .v-about__biz-hours .biz-hours__tr td { font-size: 1.4rem; padding: 10px 7px; text-align: center; } }

.v-about__biz-hours .biz-hours__th:first-of-type, .v-about__biz-hours .biz-hours__td:first-of-type { text-align: left; }

.v-about__biz-hours .biz-hours__tr_week, .v-about__biz-hours .biz-hours__tr_pm { background: #d3ecf6; }

.v-about__biz-hours .biz-hours__tr_am { background: #eaf7fb; }

.v-about__biz-hours .biz-hours__tr:not(:last-of-type) { border-bottom: 1px solid #fff; }

.v-about__flex-wrap { min-height: 106px; align-items: center; }

@media screen and (max-width: 768px) { .v-about__flex-wrap { min-height: 70px; } }

/**********************
.v-tel
**********************/
@media screen and (max-width: 768px) { #v-tel > div > div.v-tel__note-wrap { order: 6; } }

.v-tel { width: 100%; }

.v-tel__cont-wrap { align-items: flex-start; }

.v-tel__intro { margin-bottom: 50px; }

@media screen and (max-width: 768px) { .v-tel__intro { margin-bottom: 20px; order: 2; } }

.v-tel__intro-txt { letter-spacing: 0.08em; line-height: 1.4; }

@media screen and (max-width: 768px) { .v-tel__intro-txt { letter-spacing: normal; line-height: normal; } }

.v-tel__intro-txt:last-of-type { margin-bottom: 10px; }

.v-tel__note { color: #db0000; font-weight: 700; letter-spacing: 0.08em; line-height: 1.4; }

@media screen and (max-width: 768px) { .v-tel__note { letter-spacing: normal; line-height: normal; } }

.v-tel__hours-sec { margin-bottom: 50px; }

@media screen and (max-width: 768px) { .v-tel__hours-sec { margin-bottom: 20px; order: 1; } }

.v-tel__hours-ttl { text-align: left; line-height: normal; margin-bottom: 15px; }

@media screen and (max-width: 768px) { .v-tel__hours-ttl { text-align: center; letter-spacing: 0.02em; } }

.v-tel__flex-wrap { padding: 0 0 0 0; justify-content: center; }

.v-tel__flex-wrap:nth-of-type(odd) .v-tel__dl { background: #d3ecf6; }

.v-tel__flex-wrap:nth-of-type(even) .v-tel__dl { background: #eaf7fb; }

.v-tel__dl { display: flex; align-items: center; min-height: 58px; width: 100%; }

@media screen and (max-width: 768px) { .v-tel__dl { min-height: 30px; } }

.v-tel__dt { font-size: 1.8rem; letter-spacing: 0.08em; padding-left: 20px; width: 164px; }

@media screen and (max-width: 768px) { .v-tel__dt { font-size: 1.6rem; padding-left: 0px; text-align: center; width: 100%; } }

.v-tel__dd { font-size: 18px; letter-spacing: 0.08em; padding-left: 20px; letter-spacing: 0.08em; width: 406px; }

@media screen and (max-width: 768px) { .v-tel__dd { font-size: 1.6rem; padding-left: 0px; width: 100%; } }

.v-tel__msg { min-height: 380px; }

@media screen and (max-width: 768px) { .v-tel__msg { min-height: 280px; } }

.v-tel__msg.cmn-msg_second { border-radius: 10px 0px 0px 10px; margin-bottom: 15px; }

@media screen and (max-width: 768px) { .v-tel__msg.cmn-msg_second { border-radius: 10px 10px 10px 10px; margin-bottom: 20px; order: 4; } }

.v-tel__msg.cmn-msg_first { border-radius: 0px 10px 10px 0px; margin-bottom: 15px; }

@media screen and (max-width: 768px) { .v-tel__msg.cmn-msg_first { border-radius: 10px 10px 10px 10px; margin-bottom: 5px; order: 5; } }

.v-tel__msg-ttl { margin-bottom: 35px; }

@media screen and (max-width: 768px) { .v-tel__msg-ttl { margin-bottom: 20px; } }

.v-tel__msg-ttl .se { display: none; }

@media screen and (max-width: 320px) { .v-tel__msg-ttl .se { display: block; } }

.v-tel__msg-txt { display: flex; flex-wrap: wrap; justify-content: center; align-content: center; text-align: center; min-height: 207px; padding: 0 15px; }

.v-tel__link-wrap { font-size: 1.6rem; width: 100%; margin-top: 5px; }

@media screen and (max-width: 768px) { .v-tel__link-wrap { font-size: 1.4rem; } }

.v-tel__link { color: #333; font-weight: 700; position: relative; }

.v-tel__link::before { content: ''; background: #333; height: 1px; position: absolute; bottom: -5px; width: 100%; }

@media screen and (max-width: 768px) { .v-tel__note-wrap { width: 100%; order: 3; } }

.v-tel__txt_tel { color: #333; font-size: 4.2rem; font-weight: 700; }

@media screen and (max-width: 768px) { .v-tel__txt_tel { font-size: 3.0rem; } }

@media screen and (max-width: 320px) { .v-tel__txt_tel { font-size: 2.6rem; } }

.v-tel__txt_tel::before { background: url(./../img/visitor/img_tel01.png) no-repeat; content: ''; display: inline-block; width: 32px; height: 43px; margin-right: 5px; transform: translateY(5px); }

.v-tel__txt_info { line-height: 1.5; }

@media screen and (max-width: 768px) { .v-tel__txt_info { font-size: 1.4rem; letter-spacing: -0.1em; line-height: 2; width: 100%; } }

.v-tel__txt_info .se { display: block; }

@media screen and (max-width: 320px) { .v-tel__txt_info .se { display: none; } }

/**********************
enter-info
**********************/
.enter-info { background: linear-gradient(to right, #eaf7fb, #d3ecf6); padding: 60px 0 90px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .enter-info { padding: 55px 0; min-width: 100%; } }

.enter-info__ttl { margin-bottom: 82px; }

@media screen and (max-width: 768px) { .enter-info__ttl { margin-bottom: 30px; } }

@media screen and (max-width: 768px) { .enter-info__item { height: auto; } }

.enter-info__link { color: #00b5c5; font-weight: 500; }

.enter-info__link:hover { opacity: 0.7; }

/**********************
portable
**********************/
.portable { padding: 80px 0 116px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .portable { padding: 55px 0; min-width: 100%; } }

.portable__cont-wrap:not(:last-of-type) { margin-bottom: 40px; }

@media screen and (max-width: 768px) { .portable__cont-wrap:not(:last-of-type) { margin-bottom: 20px; } }

.portable__span { display: block; margin-bottom: 9px; }

@media screen and (max-width: 768px) { .portable__span { font-size: 1.6rem; margin-bottom: 15px; } }

.portable__txt { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .portable__txt { letter-spacing: normal; } }

.portable__txt:not(:last-of-type) { margin-bottom: 25px; }

@media screen and (max-width: 768px) { .portable__txt:not(:last-of-type) { margin-bottom: 15px; } }

.portable__item { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .portable__item { letter-spacing: normal; } }

.portable__item:not(:last-of-type) { margin-bottom: 25px; }

@media screen and (max-width: 768px) { .portable__item:not(:last-of-type) { margin-bottom: 15px; } }

.portable__item-note { color: #888; display: inline-block; font-size: 1.2rem; font-weight: 600; }

/**********************
access
**********************/
.access { background: linear-gradient(to right, #eaf7fb, #d3ecf6); padding: 80px 0 116px; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .access { padding: 55px 0; min-width: 100%; } }

.access__ttl { margin-bottom: 65px; }

@media screen and (max-width: 768px) { .access__ttl { margin-bottom: 30px; } }

.access__wrap { display: flex; }

@media screen and (max-width: 768px) { .access__wrap { flex-direction: column-reverse; } }

.access__table { border: 1px solid #ddd; margin-right: 60px; min-height: 194px; /* firefox に適用 */ height: 194px; }

@media (-ms-high-contrast: none) { .access__table { height: 194px; } }

@media screen and (max-width: 768px) { .access__table { margin-right: 0; width: 100%; } }

.access__tr:not(:last-of-type) { border-bottom: 1px solid #dddddd; }

.access__th { background: #f5f5f5; border-right: 1px solid #dddddd; padding: 0 20px; font-size: 1.6rem; letter-spacing: 0.075em; line-height: 1.5; text-align: left; vertical-align: middle; }

@media screen and (max-width: 768px) { .access__th { font-size: 1.3rem; letter-spacing: 0em; padding: 0 10px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .access__th { font-size: 1.1rem; padding: 0 5px; } }

.access__td { background: #fff; font-size: 1.8rem; letter-spacing: 0.075em; line-height: 1.3; padding: 0 20px; vertical-align: middle; }

@media screen and (max-width: 768px) { .access__td { font-size: 1.3rem; letter-spacing: 0em; padding: 0 10px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .access__td { padding: 0 5px; font-size: 1.1rem; } }

@media screen and (max-width: 768px) { .access__img { width: 100%; } }

/* 設定ファイル読み込み */
/**********************
treatment-mv
**********************/
.treatment__mv { background: url(./../img/treatment/img_key01.jpg) no-repeat center/cover; }

/**********************
examination
**********************/
.examination { padding: 47px 0 89px; }

@media screen and (max-width: 768px) { .examination { padding: 55px 0; } }

.examination__ttl { margin-bottom: 67px; }

@media screen and (max-width: 768px) { .examination__ttl { margin-bottom: 40px; } }

.examination__sec-wrap:not(:last-of-type) { margin-bottom: 60px; }

@media screen and (max-width: 768px) { .examination__sec-wrap:not(:last-of-type) { margin-bottom: 25px; } }

.examination__sec-wrap:nth-of-type(even) .examination__about-wrap { flex-direction: row-reverse; }

@media screen and (max-width: 768px) { .examination__sec-wrap:nth-of-type(even) .examination__about-wrap { flex-direction: column; } }

.examination__sec-wrap:nth-of-type(even) .examination__about { padding: 0 60px 0 0; }

@media screen and (max-width: 768px) { .examination__sec-wrap:nth-of-type(even) .examination__about { padding: 0; } }

.examination__sec-wrap:nth-of-type(even) .examination__msg.cmn-msg_first { border-radius: 0px 10px 10px 0px; order: 3; margin-bottom: 0; }

.examination__sec-wrap:nth-of-type(even) .examination__msg.cmn-msg_second { border-radius: 10px 0px 0px 10px; order: 2; margin-bottom: 0; }

@media screen and (max-width: 768px) { .examination__sec-wrap:nth-of-type(even) .examination__msg.cmn-msg_second { margin-bottom: 20px; } }

.examination__cont-wrap { display: flex; flex-wrap: wrap; align-items: center; }

@media screen and (max-width: 768px) { .examination__cont-wrap { flex-wrap: nowrap; flex-direction: column; justify-content: center; } }

.examination__about-wrap { display: flex; align-items: center; margin-bottom: 43px; width: 100%; }

@media screen and (max-width: 768px) { .examination__about-wrap { flex-direction: column; margin-bottom: 25px; } }

.examination__img-wrap { width: 50%; }

@media screen and (max-width: 768px) { .examination__img-wrap { margin-bottom: 5px; width: 100%; } }

.examination__img { border-radius: 20px; width: 100%; }

@media screen and (max-width: 768px) { .examination__img { border-radius: 8px; } }

.examination__about { padding-left: 60px; width: 50%; }

@media screen and (max-width: 768px) { .examination__about { padding-left: 0; width: 100%; } }

.examination__about-ttl { color: #00b5c5; font-size: 2.4rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 20px; }

@media screen and (max-width: 768px) { .examination__about-ttl { font-size: 1.8rem; text-align: center; margin-bottom: 5px; } }

.examination__about-txt { font-size: 1.6rem; letter-spacing: 0.08em; line-height: 1.5; }

@media screen and (max-width: 768px) { .examination__about-txt { font-size: 1.4rem; } }

.examination__about-txt:not(:last-of-type) { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .examination__about-txt:not(:last-of-type) { margin-bottom: 15px; } }

.examination__flex-wrap_mh_518 { min-height: 518px; }

@media screen and (max-width: 768px) { .examination__flex-wrap_mh_518 { min-height: 98px; } }

.examination__flex-wrap_mh_671 { min-height: 671px; }

@media screen and (max-width: 768px) { .examination__flex-wrap_mh_671 { min-height: 98px; } }

.examination__flex-wrap_mh_299 { min-height: 299px; }

@media screen and (max-width: 768px) { .examination__flex-wrap_mh_299 { min-height: 98px; } }

.examination__flex-wrap_mh_224 { min-height: 224px; }

@media screen and (max-width: 768px) { .examination__flex-wrap_mh_224 { min-height: 98px; } }

/**********************
surgery
**********************/
.surgery { background: linear-gradient(to right, #eaf7fb, #d3ecf6); padding: 80px 0 87px; min-width: 1320px; }

@media screen and (max-width: 768px) { .surgery { padding: 55px 0; min-width: 100%; } }

.surgery__ttl { margin-bottom: 71px; }

@media screen and (max-width: 768px) { .surgery__ttl { margin-bottom: 40px; } }

.surgery__item-ttl { letter-spacing: 0.06em; line-height: 1.5; margin-bottom: 25px; text-align: left; width: 100%; }

@media screen and (max-width: 768px) { .surgery__item-ttl { font-size: 1.8rem; letter-spacing: 0.02em; margin-bottom: 10px; } }

/**********************
results
**********************/
.results { padding: 80px 0 120px; }

@media screen and (max-width: 768px) { .results { padding: 55px 0; } }

.results__ttl { margin-bottom: 64px; }

@media screen and (max-width: 768px) { .results__ttl { margin-bottom: 40px; } }

.results__list { margin-bottom: 77px; }

@media screen and (max-width: 768px) { .results__list { margin-bottom: 55px; } }

.results__item:not(:last-of-type) { margin-bottom: 39px; }

@media screen and (max-width: 768px) { .results__item:not(:last-of-type) { margin-bottom: 25px; } }

.results__item.results__item_other { display: inline-block; margin: 0 25px 0 0; min-width: 857px; }

@media screen and (max-width: 768px) { .results__item.results__item_other { display: block; margin: 0 0 25px 0; min-width: 345px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .results__item.results__item_other { min-width: 290px; } }

.results__item.results__item_other .results__th { width: auto; }

.results__item.results__item_other .results__th:first-of-type { width: 200px; }

@media screen and (max-width: 768px) { .results__item.results__item_other .results__th:first-of-type { width: 100px; } }

.results__item.results__item_other .results__td { width: auto; }

.results__item.results__item_other .results__td:first-of-type { width: 200px; }

@media screen and (max-width: 768px) { .results__item.results__item_other .results__td:first-of-type { width: 100px; } }

.results__item.results__item_total { display: inline-block; min-width: 314px; }

@media screen and (max-width: 768px) { .results__item.results__item_total { display: block; min-width: 345px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .results__item.results__item_total { min-width: 290px; } }

@media screen and (max-width: 768px) { .results__item.results__item_total .results__table { min-width: 345px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .results__item.results__item_total .results__table { min-width: 290px; } }

.results__item.results__item_total .results__tr:first-of-type { background: #00b5c5; color: #fff; }

.results__item.results__item_total .results__th { width: auto; }

@media screen and (max-width: 768px) and (max-width: 320px) { .results__item.results__item_total .results__th:first-of-type { width: 100px; } }

.results__item.results__item_total .results__td { width: auto; }

.results__item.results__item_total .results__td:first-of-type { background: #00b5c5; color: #fff; }

@media screen and (max-width: 768px) and (max-width: 320px) { .results__item.results__item_total .results__td:first-of-type { width: 100px; } }

.results__item-ttl { color: #00b5c5; font-size: 2.4rem; font-weight: 700; letter-spacing: 0.08em; margin-bottom: 14px; }

@media screen and (max-width: 768px) { .results__item-ttl { font-size: 1.8rem; } }

@media screen and (max-width: 768px) { .results__table-wrap { overflow: scroll; } }

.results__table { border: 1px solid #dddddd; min-height: 172px; text-align: center; width: 100%; }

@media screen and (max-width: 768px) { .results__table { min-width: 600px; } }

.results__table.col .results__th { width: calc(100% / 7); }

.results__table.col .results__td { width: calc(100% / 7); }

.results__tr:first-of-type { background: #f5f5f5; }

.results__tr:not(:last-of-type) { border-bottom: 1px solid #dddddd; }

.results__th { font-weight: 400; vertical-align: middle; width: calc(100% / 6); }

@media (-ms-high-contrast: none) { .results__th { /* IE10/11 に適用 */ width: 16.6%; } }

.results__th:not(:last-of-type) { border-right: 1px solid #dddddd; }

.results__td { vertical-align: middle; width: calc(100% / 6); }

@media (-ms-high-contrast: none) { .results__td { /* IE10/11 に適用 */ width: 16.6%; } }

.results__td:first-of-type { background: #f5f5f5; border-bottom: 1px solid #dddddd; }

.results__td:not(:last-of-type) { border-right: 1px solid #dddddd; }

/* 設定ファイル読み込み */
/**********************
prp
**********************/
.prp__mv { background: url(./../img/treatment_prp/img_key01.jpg) no-repeat center/cover; }

/**********************
p-desc
**********************/
.p-desc { padding: 47px 0 120px; }

@media screen and (max-width: 768px) { .p-desc { padding: 55px 0; } }

.p-desc__ttl { margin-bottom: 66px; }

@media screen and (max-width: 768px) { .p-desc__ttl { letter-spacing: normal; margin-bottom: 30px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .p-desc__ttl { font-size: 1.9rem; } }

.p-desc__sec-wrap_l { margin-bottom: 77px; }

@media screen and (max-width: 768px) { .p-desc__sec-wrap_l { margin-bottom: 55px; } }

.p-desc__sec-wrap:not(:last-of-type) { margin-bottom: 60px; }

/**********************
p-about
**********************/
.p-about__sec-wrap { margin-bottom: 58px; }

@media screen and (max-width: 768px) { .p-about__sec-wrap { margin-bottom: 25px; } }

.p-about__wrap_intro { margin-bottom: 34px; }

@media screen and (max-width: 768px) { .p-about__wrap_intro { margin-bottom: 20px; } }

.p-about__img-wrap { text-align: center; margin: 0 auto 30px; }

@media screen and (max-width: 768px) { .p-about__img-wrap { margin: 0 auto 15px; } }

.p-about__txt { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .p-about__txt { letter-spacing: normal; font-size: 1.3rem; } }

.p-about__desc-ttl { margin-bottom: 9px; }

@media screen and (max-width: 768px) { .p-about__desc-ttl { font-size: 1.6rem; margin-bottom: 15px; } }

.p-about__desc { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .p-about__desc { letter-spacing: normal; } }

.p-about__desc:not(:last-of-type) { margin-bottom: 25px; }

@media screen and (max-width: 768px) { .p-about__desc:not(:last-of-type) { margin-bottom: 15px; } }

/**********************
p-process
**********************/
.p-process__sec-wrap { margin-bottom: 62px; }

@media screen and (max-width: 768px) { .p-process__sec-wrap { margin-bottom: 25px; } }

.p-process__sec-ttl { margin-bottom: 29px; }

@media screen and (max-width: 768px) { .p-process__sec-ttl { margin-bottom: 15px; } }

.p-process__cont-wrap { display: flex; align-items: center; justify-content: space-between; }

@media screen and (max-width: 768px) { .p-process__cont-wrap { flex-direction: column; } }

.p-process__wrap { width: calc(50% - 40px / 2); }

@media screen and (max-width: 768px) { .p-process__wrap { width: 100%; } }

.p-process__wrap.img-wrap { text-align: center; margin-right: 40px; }

@media screen and (max-width: 768px) { .p-process__wrap.img-wrap { margin: 0 0 15px 0; } }

.p-process__img { border-radius: 16px; }

.p-process__sec:not(:last-of-type) { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .p-process__sec:not(:last-of-type) { margin-bottom: 10px; } }

.p-process__ttl { margin-bottom: 10px; }

@media screen and (max-width: 768px) { .p-process__ttl { font-size: 1.6rem; margin-bottom: 0; } }

.p-process__sub-ttl { font-size: 1.8rem; font-weight: bold; letter-spacing: 0.08em; line-height: 1.3; margin-bottom: 10px; }

@media screen and (max-width: 768px) { .p-process__sub-ttl { font-size: 1.4rem; font-weight: bold; letter-spacing: normal; line-height: 1.3; margin-bottom: 5px; } }

.p-process__note_yellow { color: #ff7800; }

.p-process__item { display: flex; letter-spacing: 0.08em; margin-bottom: 10px; }

@media screen and (max-width: 768px) { .p-process__item { font-size: 1.3rem; } }

.p-process__item:nth-of-type(1)::before { content: '①'; display: inline-block; }

.p-process__item:nth-of-type(2)::before { content: '②'; display: inline-block; }

.p-process__item:nth-of-type(3)::before { content: '③'; display: inline-block; }

.p-process__item:nth-of-type(4)::before { content: '④'; display: inline-block; }

/**********************
p-therapy
**********************/
.p-therapy__sec-wrap { margin-bottom: 57px; }

@media screen and (max-width: 768px) { .p-therapy__sec-wrap { margin-bottom: 25px; } }

@media screen and (max-width: 567px) { .p-therapy__sec-ttl { min-height: 65.84px; } }

@media screen and (max-width: 768px) { .p-therapy__sec-ttl .sp { display: none; } }

@media screen and (max-width: 567px) { .p-therapy__sec-ttl .sp { display: block; } }

.p-therapy__table { border: 1px solid #ddd; margin-bottom: 38px; min-height: 200px; /* firefox に適用 */ height: 200px; width: 100%; }

@media (-ms-high-contrast: none) { .p-therapy__table { height: 200px; } }

@media screen and (max-width: 768px) { .p-therapy__table { margin-bottom: 15px; } }

.p-therapy__tr:first-of-type { text-align: left; background: #f5f5f5; }

.p-therapy__tr:not(:last-of-type) { border-bottom: 1px solid #ddd; }

.p-therapy__th { font-size: 1.8rem; font-weight: 400; letter-spacing: 0.075em; line-height: 1.3; padding: 0 20px; vertical-align: middle; }

@media screen and (max-width: 768px) { .p-therapy__th { font-size: 1.3rem; font-weight: 400; letter-spacing: normal; line-height: 1.3; padding: 0 10px; vertical-align: middle; } }

.p-therapy__th:not(:last-of-type) { border-right: 1px solid #ddd; width: 570px; }

@media screen and (max-width: 768px) { .p-therapy__th:not(:last-of-type) { width: 50%; } }

.p-therapy__td { font-size: 1.8rem; line-height: 1.3; padding: 0 20px; vertical-align: middle; }

@media screen and (max-width: 768px) { .p-therapy__td { font-size: 1.3rem; line-height: 1.3; padding: 0 10px; vertical-align: middle; } }

.p-therapy__td:not(:last-of-type) { border-right: 1px solid #ddd; width: 570px; }

@media screen and (max-width: 768px) { .p-therapy__td:not(:last-of-type) { width: 50%; } }

.p-therapy__wrap:not(:last-of-type) { margin-bottom: 35px; }

@media screen and (max-width: 768px) { .p-therapy__wrap:not(:last-of-type) { margin-bottom: 20px; } }

.p-therapy__ttl { margin-bottom: 10px; }

@media screen and (max-width: 768px) { .p-therapy__ttl { font-size: 1.6rem; margin-bottom: 15px; } }

.p-therapy__txt { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .p-therapy__txt { letter-spacing: normal; } }

/**********************
p-ours
**********************/
.p-ours__cont-wrap { display: flex; flex-wrap: wrap; align-items: center; }

@media screen and (max-width: 768px) { .p-ours__cont-wrap { flex-direction: column; } }

.p-ours__img-wrap { text-align: center; width: 50%; }

@media screen and (max-width: 768px) { .p-ours__img-wrap { margin-bottom: 15px; width: 100%; } }

.p-ours__img { border-radius: 20px; }

.p-ours__feature { padding-left: 55px; width: 50%; }

@media screen and (max-width: 768px) { .p-ours__feature { padding-left: 0; margin-bottom: 20px; width: 100%; } }

.p-ours__cost { margin-top: 40px; width: 100%; }

@media screen and (max-width: 768px) { .p-ours__cost { margin-top: 0; } }

.p-ours__ttl { margin-bottom: 12px; }

@media screen and (max-width: 768px) { .p-ours__ttl { font-size: 1.6rem; text-align: center; margin-bottom: 15px; } }

.p-ours__txt { line-height: 1.5; letter-spacing: 0.08em; }

@media screen and (max-width: 768px) { .p-ours__txt { letter-spacing: normal; } }

.p-ours__txt:not(:last-of-type) { margin-bottom: 10px; }

/**********************
other-sec
**********************/
.other-sec .cmn-link-wrap-fff { width: 380px; }

@media screen and (max-width: 768px) { .other-sec .cmn-link-wrap-fff { width: 300px; } }

@media screen and (max-width: 320px) { .other-sec .cmn-link-wrap-fff { width: 100%; } }

/* 設定ファイル読み込み */
/**********************
department
**********************/
.department__mv { background: url(./../img/department/img_key01.jpg) no-repeat center/cover; }

.department__sec { min-width: 1320px; }

@media screen and (max-width: 768px) { .department__sec { min-width: 100%; } }

.department__sec:nth-of-type(even) { background: linear-gradient(to right, #eaf7fb, #d3ecf6); }

.department__sec:nth-of-type(even) .department__symptom-wrap { order: 3; padding: 0 30px; }

@media screen and (max-width: 768px) { .department__sec:nth-of-type(even) .department__symptom-wrap { padding: 0; } }

.department__sec:nth-of-type(even) .department__img-wrap { order: 2; }

.department__sec:nth-of-type(even) .department__table { background: #fff; }

.department__cont-wrap { display: flex; align-items: flex-start; flex-wrap: wrap; justify-content: space-between; }

@media screen and (max-width: 768px) { .department__cont-wrap { flex-direction: column; align-items: flex-start; flex-wrap: nowrap; justify-content: center; } }

.department__intro { letter-spacing: 0.08em; line-height: 1.5; margin-bottom: 30px; order: 1; width: 100%; }

@media screen and (max-width: 768px) { .department__intro { letter-spacing: normal; margin-bottom: 15px; } }

.department__symptom-wrap { padding-right: 60px; order: 2; width: 50%; }

@media screen and (max-width: 768px) { .department__symptom-wrap { margin-bottom: 20px; order: 3; padding-right: 0; width: 100%; } }

.department__symptom-ttl-s { margin-bottom: 5px; }

@media screen and (max-width: 768px) { .department__symptom-ttl-s { margin-bottom: 15px; } }

.department__item { display: flex; align-items: baseline; letter-spacing: 0.08em; line-height: 1.5; }

@media screen and (max-width: 768px) { .department__item { letter-spacing: normal; } }

.department__item::before { content: '●'; color: #00b5c5; display: inline-block; font-size: 0.8rem; margin-right: 6px; transform: translateY(-2px); }

.department__item:not(:last-of-type) { margin-bottom: 15px; }

@media screen and (max-width: 768px) { .department__item:not(:last-of-type) { margin-bottom: 5px; } }

.department__img-wrap { text-align: center; order: 3; width: 50%; }

@media screen and (max-width: 768px) { .department__img-wrap { margin-bottom: 15px; width: 100%; order: 2; } }

.department__img-wrap.shadow { box-shadow: 5px 5px 10px #808080; }

/**********************
orthopedics
**********************/
.orthopedics { padding: 48px 0 90px; }

@media screen and (max-width: 768px) { .orthopedics { padding: 55px 0; } }

.orthopedics__ttl { margin-bottom: 84px; }

@media screen and (max-width: 768px) { .orthopedics__ttl { margin-bottom: 30px; } }

/**********************
rheumatism
**********************/
.rheumatism { padding: 50px 0 90px; }

@media screen and (max-width: 768px) { .rheumatism { padding: 55px 0; } }

.rheumatism__ttl { margin-bottom: 84px; }

@media screen and (max-width: 768px) { .rheumatism__ttl { margin-bottom: 30px; } }

.rheumatism__item { line-height: 2; }

@media screen and (max-width: 768px) { .rheumatism__item { line-height: 1.6; } }

/**********************
dermatology
**********************/
.dermatology { padding: 50px 0 90px; }

@media screen and (max-width: 768px) { .dermatology { padding: 55px 0; } }

.dermatology__ttl { margin-bottom: 84px; }

@media screen and (max-width: 768px) { .dermatology__ttl { margin-bottom: 30px; } }

/**********************
rehabilitation
**********************/
.rehabilitation { padding: 50px 0 90px; }

@media screen and (max-width: 768px) { .rehabilitation { padding: 55px 0; } }

.rehabilitation__ttl { margin-bottom: 84px; }

@media screen and (max-width: 768px) { .rehabilitation__ttl { margin-bottom: 30px; } }

.rehabilitation__item { line-height: 2; }

@media screen and (max-width: 768px) { .rehabilitation__item { line-height: 1.6; } }

/* 設定ファイル読み込み */
/**********************
news-list-mv
**********************/
.news-list__mv { background: url(./../img/news/img_key01.jpg) no-repeat center/cover; }

.news-list__sec-wrap { padding: 90px 0; }

@media screen and (max-width: 768px) { .news-list__sec-wrap { padding: 55px 0; margin: 0; } }

.news-list__ttl { font-size: 3.3rem; margin-bottom: 90px; }

@media screen and (max-width: 768px) { .news-list__ttl { font-size: 2.1rem; margin-bottom: 45px; } }

.news-list__list { margin: 0 auto; }

@media screen and (max-width: 768px) { .news-list__list { margin: 0 auto; width: 100%; } }

.news-list__item-link { font-weight: 400; }

.news-list__item-link:hover, .news-list__item-link:hover .news-list__time { color: #00b5c5; }

.news-list__time { letter-spacing: 0.08em; margin-right: 20px; }

/* 設定ファイル読み込み */
/**********************
news-content
**********************/
.news-content__wrap { padding: 90px 0; }

@media screen and (max-width: 768px) { .news-content__wrap { padding: 55px 0; margin: 0; } }

.news-content__article { margin-bottom: 60px; }

@media screen and (max-width: 768px) { .news-content__article { margin-bottom: 30px; } }

.news-content__time { display: block; margin-bottom: 20px; }

@media screen and (max-width: 768px) { .news-content__time { margin-bottom: 10px; } }

.news-content__ttl { display: flex; font-size: 3.3rem; font-weight: bold; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .news-content__ttl { font-size: 2.1rem; margin-bottom: 30px; } }

.news-content__ttl::before { content: ''; background: #00b5c5; border-radius: 33px; display: inline-block; height: auto; width: 5px; margin: 10px 20px 10px 0; transform: translateY(4px); }

@media screen and (max-width: 768px) { .news-content__ttl::before { margin: 5px 10px 5px 0; transform: translateY(2px); width: 5px; } }

.news-content__txt { line-height: 2; }

@media screen and (max-width: 768px) { .news-content__txt { line-height: 1.5; } }

.news-content__txt:not(:last-of-type) { margin-bottom: 20px; }

.news-content__txt-link { color: #00b5c5; font-weight: 700; text-decoration: underline; }

.news-content__link-wrap { width: 50%; }

@media screen and (max-width: 567px) { .news-content__link-wrap { width: 100%; } }

/* 設定ファイル読み込み */
/**********************
greeting
**********************/
.greeting__mv { background: url(./../img/about_greeting/img_key01.jpg) no-repeat center/cover; }

.greeting__sec-wrap { padding: 47px 0 120px; }

@media screen and (max-width: 768px) { .greeting__sec-wrap { padding: 55px 0; } }

.greeting__ttl { margin-bottom: 90px; }

@media screen and (max-width: 768px) { .greeting__ttl { margin-bottom: 30px; } }

.greeting__cont-wrap { margin-bottom: 77px; }

@media screen and (max-width: 768px) { .greeting__cont-wrap { margin-bottom: 55px; } }

/**********************
director
**********************/
.director { display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 85px; }

@media screen and (max-width: 768px) { .director { flex-direction: column; margin-bottom: 35px; } }

.director__img-wrap { width: 300px; flex-basis: 300px; max-width: 300px; }

@media screen and (max-width: 768px) { .director__img-wrap { margin: 0 auto; margin-bottom: 25px; } }

.director__txt-wrap { width: 870px; flex-basis: 870px; max-width: 870px; }

@media screen and (max-width: 768px) { .director__txt-wrap { flex-basis: auto; width: 100%; } }

.director__name { font-size: 2.7rem; font-weight: 700; letter-spacing: 0.08em; line-height: 1.3; margin-bottom: 35px; }

@media screen and (max-width: 768px) { .director__name { font-size: 1.8rem; letter-spacing: normal; margin-bottom: 20px; } }

.director__ttl { display: block; font-weight: 700; }

.director__meg { letter-spacing: 0.08em; line-height: 2; }

@media screen and (max-width: 768px) { .director__meg { letter-spacing: normal; line-height: normal; } }

/**********************
history
**********************/
.history { margin: 0 0 90px; }

@media screen and (max-width: 768px) { .history { margin: 0 0 25px; } }

.history__sec-ttl { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .history__sec-ttl { margin-bottom: 15px; } }

.history__dl { border-bottom: 1px solid #ddd; display: flex; align-items: center; min-height: 60px; padding: 20px 0; }

@media screen and (max-width: 768px) { .history__dl { flex-direction: column; min-height: 100%; padding: 5px 0; } }

.history__dt { font-size: 1.8rem; letter-spacing: 0.075em; line-height: 1.3; margin-right: 90px; min-width: 150px; }

@media screen and (max-width: 768px) { .history__dt { font-size: 1.3rem; line-height: normal; margin-right: 0; min-width: 100%; } }

.history__dt_term { min-width: 320px; }

@media screen and (max-width: 768px) { .history__dt_term { min-width: 100%; } }

.history__dd { font-size: 1.8rem; letter-spacing: 0.075em; line-height: 1.4; min-width: 945px; }

@media screen and (max-width: 768px) { .history__dd { font-size: 1.3rem; letter-spacing: normal; line-height: normal; min-width: 100%; width: 100%; } }

.history__dd_term { min-width: 545px; }

@media screen and (max-width: 768px) { .history__dd_term { min-width: 100%; } }

.history__detail { display: block; font-size: 1.2rem; letter-spacing: 0.075em; line-height: 1.5; }

@media screen and (max-width: 768px) { .history__detail { display: block; font-size: 1.1rem; letter-spacing: normal; line-height: normal; } }

.history__time { font-weight: 700; }

/* 設定ファイル読み込み */
/**********************
research
**********************/
.research__mv { background: url(./../img/about_research/img_key01.jpg) no-repeat center/cover; }

.research__sec_l { padding: 77px 0; min-width: 1320px; }

@media screen and (max-width: 768px) { .research__sec_l { padding: 55px 0; min-width: 100%; } }

.research__sec_l:nth-of-type(even) { background: linear-gradient(to right, #eaf7fb, #d3ecf6); }

.research__ttl { margin-bottom: 90px; }

@media screen and (max-width: 768px) { .research__ttl { margin-bottom: 30px; } }

.research__sec_m:not(:last-of-type) { margin-bottom: 60px; }

@media screen and (max-width: 768px) { .research__sec_m:not(:last-of-type) { margin-bottom: 30px; } }

.research__sec_s { padding-bottom: 30px; border-bottom: 1px solid #ddd; }

@media screen and (max-width: 768px) { .research__sec_s { padding-bottom: 20px; } }

.research__sec_s:not(:last-of-type) { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .research__sec_s:not(:last-of-type) { margin-bottom: 20px; } }

.research__desc-ttl { font-size: 1.8rem; }

@media screen and (max-width: 768px) { .research__desc-ttl { font-size: 1.5rem; } }

.research__txt01 { font-size: 1.6rem; line-height: 2; }

@media screen and (max-width: 768px) { .research__txt01 { font-size: 1.4rem; line-height: 1.5; } }

.research__txt02 { color: #696969; font-size: 1.2rem; font-weight: 700; line-height: 2; }

@media screen and (max-width: 768px) { .research__txt02 { font-size: 1.2rem; line-height: 1.5; } }

.research__other-sec { padding: 77px 0 120px; }

@media screen and (max-width: 768px) { .research__other-sec { padding: 55px 0; } }

/* 設定ファイル読み込み */
/**********************
directors
**********************/
.directors__mv { background: url(./../img/about_directors/img_key01.jpg) no-repeat center/cover; }

.directors__sec-wrap_history { margin: 0 0 90px; }

@media screen and (max-width: 768px) { .directors__sec-wrap_history { margin: 0 0 25px; } }

.directors__sec-ttl_history { margin-bottom: 30px; }

@media screen and (max-width: 768px) { .directors__sec-ttl_history { margin-bottom: 15px; } }

.directors__dl_history { border-bottom: 1px solid #ddd; display: flex; align-items: center; min-height: 60px; padding: 20px 0; }

@media screen and (max-width: 768px) { .directors__dl_history { flex-direction: column; min-height: 100%; padding: 5px 0; } }

.directors__dt_history { font-size: 1.8rem; letter-spacing: 0.075em; line-height: 1.3; margin-right: 60px; min-width: 90px; }

@media screen and (max-width: 768px) { .directors__dt_history { font-size: 1.3rem; line-height: normal; margin-right: 0; min-width: 100%; } }

.directors__dt_history.term { min-width: 320px; }

@media screen and (max-width: 768px) { .directors__dt_history.term { min-width: 100%; } }

.directors__dd_history { font-size: 1.8rem; letter-spacing: 0.075em; line-height: 1.4; min-width: 945px; }

@media screen and (max-width: 768px) { .directors__dd_history { font-size: 1.3rem; letter-spacing: normal; line-height: normal; min-width: 100%; width: 100%; } }

.directors__dd_history.term { min-width: 545px; }

@media screen and (max-width: 768px) { .directors__dd_history.term { min-width: 100%; } }

.directors__detail_history { display: block; font-size: 1.2rem; letter-spacing: 0.075em; line-height: 1.5; }

@media screen and (max-width: 768px) { .directors__detail_history { display: block; font-size: 1.1rem; letter-spacing: normal; line-height: normal; } }

.directors__time_history { font-weight: 700; }

.directors__item { margin-bottom: 75px; }

@media screen and (max-width: 768px) { .directors__item { margin-bottom: 45px; } }

.directors__item-inner { display: flex; align-items: center; }

@media screen and (max-width: 768px) { .directors__item-inner { flex-wrap: wrap; } }

.directors__thumb { flex-basis: 300px; max-width: 300px; width: 300px; display: block; }

@media screen and (max-width: 768px) { .directors__thumb { flex-basis: 100%; width: 100%; max-width: 100%; text-align: center; } }

.directors__desc { padding-left: 30px; width: calc(100% - 330px); flex-basis: calc(100% - 330px); max-width: calc(100% - 330px); }

@media screen and (max-width: 768px) { .directors__desc { padding-left: 0; padding-top: 20px; flex-basis: 100%; width: 100%; max-width: 100%; } }

.directors__head { font-size: 2.7rem; margin-bottom: 15px; }

@media screen and (max-width: 768px) { .directors__head { font-size: 1.8rem; text-align: center; } }

.directors__data { font-weight: 700; display: block; font-size: 1.6rem; }

@media screen and (max-width: 768px) { .directors__data { font-size: 1,4rem; } }

.directors__name { font-weight: 700; display: block; }

.directors__details { line-height: 2; }

@media screen and (max-width: 768px) { .directors__details { line-height: 1.5; } }

.directors__histories { margin-top: 30px; margin-bottom: 30px; }

@media screen and (max-width: 768px) { .directors__histories { margin: 20px 0; } }

.directors__detail-list { background-color: #fff; padding: 30px; border-radius: 10px; }

@media screen and (max-width: 768px) { .directors__detail-list { padding: 15px; border: 6px; } }

.directors__detail-item { margin-bottom: .75em; line-height: 1.75; display: flex; }

.directors__detail-item:before { content: "＊"; display: block; margin-right: .5em; color: #00b5c5; font-weight: 700; }

.directors__detail-item:nth-of-type(even):before { color: #0395a2; }

.directors__detail-item:last-of-type { margin-bottom: 0; }

.directors__link-wrap_other { display: inline-block; }

@media screen and (max-width: 768px) { .directors__link-wrap_other { display: block; } }

.directors__link-wrap_other.cmn-link-fff { width: 380px; }

@media screen and (max-width: 768px) { .directors__link-wrap_other.cmn-link-fff { width: 300px; } }

@media screen and (max-width: 768px) and (max-width: 320px) { .directors__link-wrap_other.cmn-link-fff { width: 100%; } }

.directors__link-wrap_other:not(:last-of-type) { margin-right: 60px; }

@media screen and (max-width: 768px) { .directors__link-wrap_other:not(:last-of-type) { margin: 0 auto 25px; } }

/**********************
map
**********************/
.map { text-align: center; min-width: 1320px; width: 100%; }

@media screen and (max-width: 768px) { .map { min-width: 100%; } }

.map__iframe { border: none; height: calc(450/1400*100vw); width: 100%; }

@media screen and (max-width: 768px) { .map__iframe { height: calc(450/375*50vw); } }
