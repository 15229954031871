@charset "utf-8";
/* 設定ファイル読み込み */
@import "_function";

/**********************
greeting
**********************/
.greeting {
    &__mv {
        background: url(./../img/about_greeting/img_key01.jpg) no-repeat center / cover;
        @include sp() {
        }
    }
    &__sec-wrap {
        padding: 47px 0 120px;
        @include sp() {
            padding: 55px 0;
        }
    }
    &__inner {
        @include sp() {
            
        }
    }
    &__ttl {
        margin-bottom: 90px;
        @include sp() {
            // letter-spacing: normal;
            margin-bottom: 30px;
        }
    }
    &__cont-wrap {
        margin-bottom: 77px;
        @include sp() {
            margin-bottom: 55px;
        }
    }
}
/**********************
director
**********************/
.director {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 85px;
    @include sp() {
        flex-direction: column;
        margin-bottom: 35px;
    }
    &__img-wrap {
        width: 300px;
        flex-basis: 300px;
        max-width: 300px;
        @include sp() {
            margin: 0 auto;
            margin-bottom: 25px;
            // width: 50%;
        }
    }
    &__img {
        @include sp() {
        }
    }
    &__txt-wrap {
        width: 870px;
        flex-basis: 870px;
        max-width: 870px;
        @include sp() {
            flex-basis: auto;
            width: 100%;
        }
    }
    &__name {
        font-size: 2.7rem;
        font-weight: 700;
        letter-spacing: 0.08em;
        line-height: 1.3;
        margin-bottom: 35px;
        @include sp() {
            font-size: 1.8rem;
            letter-spacing: normal;
            margin-bottom: 20px;
        }
    }
    &__ttl {
        display: block;
        font-weight: 700;
        @include sp() {
            
        }
    }
    &__msg-wrap {
        @include sp() {
            
        }
    }
    &__meg {
        letter-spacing: 0.08em;
        line-height: 2;
        @include sp() {
            letter-spacing: normal;
            line-height: normal;
        }
    }
}
/**********************
history
**********************/
.history {
    margin: 0 0 90px;
    @include sp() {
        margin: 0 0 25px;
    }
    &__sec-ttl {
        margin-bottom: 30px;
        @include sp() {
            margin-bottom: 15px;
        }
    }
    &__cont-wrap {
        @include sp() {

        }
    }
    &__dl {
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 20px 0;
        @include sp() {
            flex-direction: column;
            min-height: 100%;
            padding: 5px 0;
        }
    }
    &__dt {
        font-size: 1.8rem;
        letter-spacing: 0.075em;
        line-height: 1.3;
        margin-right: 90px;
        min-width: 150px;
        @include sp() {
            // font-size: 1.4rem;
            font-size: 1.3rem;
            // letter-spacing: 0.075em;
            line-height: normal;
            margin-right: 0;
            min-width: 100%;
        }
    }
    &__dt_term {
        min-width: 320px;
        @include sp() {
            min-width: 100%;
        }
    }
    &__dd {
        font-size: 1.8rem;
        letter-spacing: 0.075em;
        line-height: 1.4;
        min-width: 945px;
        @include sp() {
            // font-size: 1.4rem;
            font-size: 1.3rem;
            letter-spacing: normal;
            line-height: normal;
            min-width: 100%;
            width: 100%;
            // padding-left: 10px;
        }
    }
    &__dd_term {
        min-width: 545px;
        @include sp() {
            min-width: 100%;
        }
    }
    &__detail {
        display: block;
        font-size: 1.2rem;
        letter-spacing: 0.075em;
        line-height: 1.5;
        @include sp() {
            display: block;
            font-size: 1.1rem;
            letter-spacing: normal;
            line-height: normal;
        }
    }
    &__time {
        font-weight: 700;
        @include sp() {
        }
    }
}

